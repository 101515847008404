import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ComponentModule } from 'app/core/components/components.module';

import { SctSegmentationComponent } from './sct-segmentation.component';

@NgModule({
    imports: [ComponentModule, FormsModule],
    declarations: [SctSegmentationComponent],
    exports: [SctSegmentationComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SctSegmentationModule {}
