import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GlobalService {
    // Alert
    public alertSubj: BehaviorSubject<any> = new BehaviorSubject(null);
    public alertObservable = this.alertSubj.asObservable();
    // Loading
    public loadingSubj: BehaviorSubject<any> = new BehaviorSubject(false);
    public loadingObservable = this.loadingSubj.asObservable();

    public alert(title: string, text: string | string[]): void {
        this.alertSubj.next({ title: title, text: text });
    }
    public loading(show: boolean): void {
        this.loadingSubj.next(show);
    }
    public loadingText(text: string): void {
        this.loadingSubj.next({ isObj: true, show: true, text: text });
    }
    public localStorageItem(id: string): string {
        return localStorage.getItem(id);
    }
}
