import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-scroll-top',
    templateUrl: './scroll-top.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ScrollTopComponent {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}
}
