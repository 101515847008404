/* eslint-disable @typescript-eslint/no-explicit-any */
import { Routes } from '@angular/router';
import { environment } from 'environments/environment';
export const rhgRoutes: Routes = [
    {
        path: 'dashboard/index',
        loadChildren: (): any =>
            import('app/clients/rhg/dashboards/index/index.module').then(
                (m) => m.IndexModule
            )
    },
    {
        path: 'dashboard/docs',
        loadChildren: (): any =>
            import('app/clients/rhg/dashboards/docs/docs.module').then(
                (m) => m.DocsModule
            )
    },
    {
        path: 'dashboard/radisson-academy',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/radisson-academy/radisson-academy.module'
            ).then((m) => m.RadissonAcademyModule)
    },
    {
        path: 'dashboard/mobile-app-docs',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/mobile-app-docs/mobile-app-docs.module'
            ).then((m) => m.MobileAppDocsModule)
    },
    // Revops dashboards
    {
        path: 'dashboard/pace-report',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/pace-report/pace-report.module'
            ).then((m) => m.PaceReportModule)
    },
    {
        path: 'dashboard/rhg-marketshare',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/rhg-marketshare/rhg-marketshare.module'
            ).then((m) => m.RhgMarketshareModule)
    },
    {
        path: 'dashboard/forecast-accuracy',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/forecast-accuracy/forecast-accuracy.module'
            ).then((m) => m.ForecastAccuracyModule)
    },
    {
        path: 'dashboard/room-type',
        loadChildren: (): any =>
            import('app/clients/rhg/dashboards/revops/room-type/room-type.module').then(
                (m) => m.RoomTypeModule
            )
    },
    {
        path: 'dashboard/system-contribution2',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/system-contribution2/system-contribution2.module'
            ).then((m) => m.SystemContribution2Module)
    },
    {
        path: 'dashboard/system-contribution-models',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/system-contribution-models/system-contribution-models.module'
            ).then((m) => m.SystemContributionModelsModule)
    },
    {
        path: 'dashboard/channel-mix',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/channel-mix/channel-mix.module'
            ).then((m) => m.ChannelMixModule)
    },
    {
        path: 'dashboard/segment-mix',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/segment-mix/segment-mix.module'
            ).then((m) => m.SegmentMixModule)
    },
    {
        path: 'dashboard/cost-acquisition',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/cost-acquisition/cost-acquisition.module'
            ).then((m) => m.CostAcquisitionModule)
    },
    {
        path: 'dashboard/fc-evo',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/forecast-evolution/forecast-evolution.module'
            ).then((m) => m.ForecastEvolutionModule)
    },
    {
        path: 'dashboard/ota',
        loadChildren: (): any =>
            import('app/clients/rhg/dashboards/revops/ota/ota.module').then(
                (m) => m.OTAModule
            )
    },
    {
        path: 'dashboard/pickup',
        loadChildren: (): any =>
            import('app/clients/rhg/dashboards/revops/pickup/pickup.module').then(
                (m) => m.PickupModule
            )
    },
    {
        path: 'dashboard/agency-360',
        loadChildren: (): any =>
            import('app/clients/rhg/dashboards/revops/agency-360/agency-360.module').then(
                (m) => m.Agency360Module
            )
    },
    {
        path: 'dashboard/brand-com',
        loadChildren: (): any =>
            import('app/clients/rhg/dashboards/revops/brand-com/brand-com.module').then(
                (m) => m.BrandComModule
            )
    },
    {
        path: 'dashboard/pulse-pivot',
        loadChildren: (): any =>
            import('app/clients/rhg/dashboards/revops/pivot/pulse-pivot.module').then(
                (m) => m.PulsePivotlModule
            )
    },
    {
        path: 'dashboard/campaign-promotion-overview',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/campaign-promotion/campaign-promotion.module'
            ).then((m) => m.CampaignPromotionModule)
    },
    {
        path: 'dashboard/pulse-pivot-v2',
        loadChildren: (): any =>
            import('app/clients/rhg/dashboards/revops/pivot2/pulse-pivot.module').then(
                (m) => m.PulsePivotlModule
            )
    },
    {
        path: 'dashboard/corporate-pickup',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/corporate-pickup/corporate-pickup.module'
            ).then((m) => m.CorporatePickupModule)
    },
    {
        path: 'dashboard/events-calendar',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/events/events.module'
            ).then((m) => m.EventsDashboardModule)
    },
    // Distribution dashboards
    {
        path: 'dashboard/demand-360',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/distribution/demand-360/demand-360.module'
            ).then((m) => m.Demand360Module)
    },
    // S&M dashboards
    {
        path: 'dashboard/sales-production',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/sales-production/sales-production.module'
            ).then((m) => m.SalesProductionModule)
    },
    {
        path: 'dashboard/sp-executive',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/sp-executive/sp-executive.module'
            ).then((m) => m.SPExecutiveModule)
    },
    {
        path: 'dashboard/me-intel',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/me-intel/me-intel.module'
            ).then((m) => m.MEIntelModule)
    },
    {
        path: 'dashboard/me-ent',
        loadChildren: (): any =>
            import('app/clients/rhg/dashboards/sales-meetings/me-ent/me-ent.module').then(
                (m) => m.MEEntModule
            )
    },
    {
        path: 'dashboard/me-leads',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/me-leads/me-leads.module'
            ).then((m) => m.MELeadsModule)
    },
    {
        path: 'dashboard/me-space',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/me-space/me-space.module'
            ).then((m) => m.MESpaceModule)
    },
    {
        path: 'dashboard/me-connectivity',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/me-connectivity/me-connectivity.module'
            ).then((m) => m.MEConnectivityModule)
    },
    {
        path: 'dashboard/me-cxl',
        loadChildren: (): any =>
            import('app/clients/rhg/dashboards/sales-meetings/me-cxl/me-cxl.module').then(
                (m) => m.MECxlModule
            )
    },
    {
        path: 'dashboard/account-portfolio',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/account-portfolio/account-portfolio.module'
            ).then((m) => m.AccountPortfolioModule)
    },
    {
        path: 'dashboard/account-manager',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/sales-meetings/account-manager/account-manager.module'
            ).then((m) => m.AccountManagerModule)
    },
    {
        path: 'dashboard/rate-code-production',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/rate-code-production/rate-code-production.module'
            ).then((m) => m.RateCodeModule)
    },
    // Operations dashboards
    {
        path: 'dashboard/operational-report',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/finance/operational-report/operational-report.module'
            ).then((m) => m.OperationalReportModule)
    },
    {
        path: 'dashboard/quality-report',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/operations/quality-report/quality-report.module'
            ).then((m) => m.QualityReportModule)
    },
    {
        path: 'dashboard/quality-review-pro',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/operations/quality-review-pro/quality-review-pro.module'
            ).then((m) => m.QualityReviewProModule)
    },
    {
        path: 'dashboard/loyalty',
        loadChildren: (): any =>
            import('app/clients/rhg/dashboards/operations/loyalty/loyalty.module').then(
                (m) => m.LoyaltyModule
            )
    },
    // Presentations dashboards
    {
        path: 'dashboard/revco_v2',
        loadChildren: (): any =>
            import('app/clients/rhg/dashboards/presentations/revco2/revco2.module').then(
                (m) => m.Revco2Module
            )
    },
    {
        path: 'dashboard/area_performance',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/presentations/area-performance/area-performance.module'
            ).then((m) => m.AreaPerformanceModule)
    },
    {
        path: 'dashboard/revgen',
        loadChildren: (): any =>
            import('app/clients/rhg/dashboards/presentations/revgen/revgen.module').then(
                (m) => m.RevgenModule
            )
    },

    // Data Status Dashboard
    {
        path: 'dashboard/import-control',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/data-control/import-control/import-control.module'
            ).then((m) => m.ImportControlModule)
    },
    // if environment is not production, show the test dashboards
    /* ...(environment.name != 'prod'
        ? [ */
    {
        path: 'dashboard/productivity-home-page',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-home-page/prod-home-page.module'
            ).then((m) => m.ProductivityHomePageModule)
    },
    {
        path: 'dashboard/productivity-dashboard',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-dashboard/prod-dashboard.module'
            ).then((m) => m.ProductivityDashboardModule)
    },
    {
        path: 'dashboard/productivity-accuracy',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-accuracy/prod-accuracy.module'
            ).then((m) => m.ProductivityAccuracyModule)
    },
    {
        path: 'dashboard/productivity-benchmark',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-benchmark/prod-benchmark.module'
            ).then((m) => m.ProductivityBenchmarkModule)
    },
    {
        path: 'dashboard/productivity-perspective',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-management-perspective/prod-management-perspective.module'
            ).then((m) => m.ProductivityManagementPerspectiveModule)
    },
    {
        path: 'dashboard/productivity-cockpit',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-cockpit/prod-cockpit.module'
            ).then((m) => m.ProductivityCockpitModule)
    },
    {
        path: 'dashboard/productivity-targets',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/prod-targets/prod-targets.module'
            ).then((m) => m.ProductivityTargetsModule)
    },
    {
        path: 'dashboard/productivity-admin',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/productivity/admin/prod-admin.module'
            ).then((m) => m.ProductivityAdminModule)
    },
    {
        path: 'dashboard/orma-dashboard',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/revops/orma/orma-dashboard/orma-dashboard.module'
            ).then((m) => m.OrmaDashboardModule)
    },
    // Displacement Tool
    {
        path: 'dashboard/displacement-tool',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/displacement/displacement-tool/displacement-tool.component.module'
            ).then((m) => m.DisplacementToolModule)
    },
    {
        path: 'dashboard/displacement-config',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/displacement/displacement-config/displacement-config.component.module'
            ).then((m) => m.DisplacementConfigModule)
    },
    {
        path: 'dashboard/displacement-pricing',
        loadChildren: (): any =>
            import(
                'app/clients/rhg/dashboards/displacement/displacement-pricing/displacement-pricing.component.module'
            ).then((m) => m.DisplacementPricingModule)
    }

    /* ]
        : []) */
];
