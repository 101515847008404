import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from '@sentry/browser';
import { UserService } from 'app/core/services/user.service';
@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
    public file = '';
    public loading: boolean;
    public user: User;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor(private userService: UserService) {}

    ngOnInit(): void {
        this.user = this.userService.user;
        this.file = this.user.profile_image
            ? '/users-images/' + this.user.profile_image
            : '../../../../assets/app/media/img/users/user-128.png';
    }
}
