<div id="alert" [ngClass]="show ? 'active' : 'inactive'">
	<div class="alert-inner">
		<div class="alert-box">
			<div class="alert-box-inner clearfix"
				[ngClass]="show ? 'alert-box-inner_active' : 'alert-box-inner_inactive'">
				<h2>{{title}}</h2>
				<ng-container *ngIf="textIsArray">
					<ng-container *ngFor="let tx of text">
						<div class="text-content">{{tx}}</div>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="!textIsArray">
					<div class="text-content">{{text}}</div>
				</ng-container>
				<button mat-raised-button class="float-right" (click)="close()">Ok</button>
			</div>
		</div>
	</div>
</div>