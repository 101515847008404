<div id="dashboard-page" class="m-grid m-grid--hor m-grid--root m-page">
	<dashboard-header-nav></dashboard-header-nav>
	<!-- begin::Body -->
	<div id="slider" class="m-body m-page main-content">
		<router-outlet name="dashboard-filters"></router-outlet>
		<div id="page-container" class="m-page__container mat-app-background py-0">
			<router-outlet></router-outlet>
		</div>
	</div>
	<!-- end::Body -->
	<!-- begin::Footer -->
	<footer class="m-grid__item m-footer m-page main-content app-footer" appunwraptag=""
	[ngClass]="{ 'app-footer-home-page': currentRoute == '/dashboard/index' }">
		<div class="m-container m-container--responsive m-container--xxl m-container--full-height m-page__container mx-0">
			<div class="m-footer__wrapper">
				<div class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
					<div class="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
						<span class="m-footer__copyright">
							{{currentYear}} &copy; Pulse by
							<a href="https://www.radissonhotelgroup.com/" target="_blank" class="m-link">
								RHG
							</a>
						</span>
					</div>
					<!--
						<div class="m-stack__item m-stack__item--right m-stack__item--middle m-stack__item--first">
							<ul class="m-footer__nav m-nav m-nav--inline m--pull-right">
								<li class="m-nav__item">
									<button class="m-nav__link">
										<span class="m-nav__link-text">
											About
										</span>
									</button>
								</li>
								<li class="m-nav__item">
									<button  class="m-nav__link">
										<span class="m-nav__link-text">
											Privacy
										</span>
									</button>
								</li>
								<li class="m-nav__item">
									<button class="m-nav__link">
										<span class="m-nav__link-text">
											T&C
										</span>
									</button>
								</li>
								<li class="m-nav__item">
									<button class="m-nav__link">
										<span class="m-nav__link-text">
											Purchase
										</span>
									</button>
								</li>
								<li class="m-nav__item m-nav__item--last">
									<button class="m-nav__link" data-toggle="m-tooltip" title="Support Center" data-placement="left">
										<i class="m-nav__link-icon flaticon-info m--icon-font-size-lg3"></i>
									</button>
								</li>
							</ul>
						</div>
					-->
				</div>
			</div>
		</div>
	</footer>
	<!-- end::Footer -->
</div>