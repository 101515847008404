<!-- begin:: Page -->
<router-outlet></router-outlet>
<!-- end:: Page -->
<entity-sidebar></entity-sidebar>
<app-scroll-top></app-scroll-top>
<!--begin::Base Scripts -->
<!--end::Base Scripts -->
<!--begin::Page Vendors -->
<!--end::Page Vendors -->
<!--begin::Page Snippets -->
<!--end::Page Snippets -->
<div id="m_quick_sidebar_toggle"></div>
<alert></alert>
<loading></loading>
<div bsModal #alertModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-alert-name">
  <div class="modal-dialog modal-md modal-alert" [hidden]="alertHidden">
    <div class="modal-content" [ngClass]="{
    'border-alert':alertData?.author === 'ALERT',
    'border-info':alertData?.author === 'INFO'
    }">
      <div class="modal-header" style="display: flex">
        <h4 id="dialog-alert-name" class="modal-title pull-left"><i class="fa" [ngClass]="{
    'fa-exclamation-triangle titleAlert':alertData?.author === 'ALERT',
    'fa-info-circle titlInfo':alertData?.author === 'INFO'
    }"></i>{{ alertData?.title }}</h4>
        <button type="button" class="close btn-close text-reset" aria-label="Close" (click)="hideAlertModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body" [innerHTML]="alertData?.description" style="text-align: center;">
      </div>
      <div style="border-top: 1px solid #dee2e6; text-align: center; padding: 15px;">
        <button type="button" (click)="onAlertAcknowledge()" class="btn text-white" [ngClass]="{
    'btn-danger':alertData?.author === 'ALERT',
    'btn-info':alertData?.author === 'INFO'
    }">Acknowledge</button>
      </div>
    </div>
  </div>
</div>
