/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { DxListComponent, DxTreeListComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { DashboardFilter } from 'app/main/entity-sidebar/entity-sidebar.constants';
import { ServerService } from './server.service';
import { checkAllowedHtl } from 'app/shared/utils/shared-methods.utils';
import { EventManagerService } from 'app/shared/utils/event-manager.service';
import { ExcelService } from '../services/excel.export.service';
import { LazyLoadExcelService } from '../services/lazyload.export.excel';
import { OrmaService } from '../services/orma.service';
import { Widget } from '../components/widgets/widgets';

@Injectable({ providedIn: 'root' })
export class DashboardService {
    public id: string;
    public url: string;
    public options: DashboarOption[];
    public view: any[];
    public staticData: any[];
    public offsetColumn: boolean;
    public data: any = null;
    public title: string;
    public sections: any[];
    public options_title: string;
    public queryOnce: boolean;
    public selectedOptions: any; // Object (not an array!)
    public selectedEntities: string[];
    public selectedHtlId: number[];
    public on_init: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public on_change: BehaviorSubject<any> = new BehaviorSubject(false);
    public on_slide: BehaviorSubject<boolean> = new BehaviorSubject(false);
    // Filters variables (see dashboard-filters.component.ts)
    public showOrHideFilters: Function;
    public initFilters: Function;
    public onSourceChange: Function; // Not an entity filter
    public hasEntityFilters: boolean;
    public filtersMap: Map<string, DashboardFilter>;
    public dxMapPromise: Promise<any>;
    public dxTreeFiltersMap: Map<string, DxTreeListComponent>;
    public dxListFiltersMap: Map<string, DxListComponent>;
    public selectedFilters: any; // Object (not an array!)
    public allowedHtlIds: number[];
    public isAllowed = false;
    public _widgets:Widget[];
    // Subject unsubcribe
    private ngUnsubscribe: Subject<void>;
    constructor(
        private serverService: ServerService,
        public excelservice: ExcelService,
        public lazyLoadExcelService: LazyLoadExcelService,
        public ormaservice: OrmaService,
        private eventManagerService: EventManagerService
    ) {}
    public reset(unsubscribe: Subject<void>): void {
        this.title = null;
        this.options_title = null;
        this.options = null;
        this.data = null;
        this.url = null;
        this.view = null;
        this.staticData = null;
        this.offsetColumn = null;
        // Set subject
        this.ngUnsubscribe = unsubscribe;
        // Reset also the behaviors
        this.on_init = new BehaviorSubject(false);
        this.on_change = new BehaviorSubject(false);
        this.on_slide = new BehaviorSubject(false);
    }
    /**
     * Update the selectedOptions and triggers this.update
     * @param optionsObj
     */
    public updateOptions(optionsObj: any): void {
        this.selectedOptions = optionsObj;
        // Object.keys(this.selectedOptions)?.forEach((key) => {
        //     localStorage.setItem(key, this.selectedOptions[key]);
        // });
        if (typeof this.selectedOptions.currency === 'number')
            localStorage.setItem('currency', this.selectedOptions.currency);
        if (this.selectedOptions.mkt_group) {
            localStorage.setItem('mkt_group', this.selectedOptions.mkt_group);
        } else {
            localStorage.setItem('mkt_group', '');
        }
        this.update('options');
    }
    /**
     * Set the selectedFilters by this.filters,
     * ignore filters without useOnSelectionAs
     */
    public setSelectedFilters(): any {
        const filtersObj: any = {};
        this.filtersMap.forEach((f: DashboardFilter) => {
            if (f.useOnSelectionAs && !f.hidden) {
                if (f.type) {
                    filtersObj[f.id] = f.selectedKeys;
                } else {
                    const _dxTree = this.dxTreeFiltersMap.get(f.id);
                    const _key = f.useOnSelectionAs === 1 ? f.idKey : f.textKey;
                    filtersObj[f.id] = [
                        ...new Set(
                            _dxTree.instance
                                .getSelectedRowsData('all')
                                .filter((node) => node.extra === undefined)
                                .map((node) => node[_key])
                        )
                    ];
                }
            }
        });
        this.selectedFilters = filtersObj;
        return this.selectedFilters;
    }
    public updateFiltersSeletedKeysBackup(): void {
        this.filtersMap.forEach((f: DashboardFilter) => {
            f.selectedKeysBackup = [...new Set(f.selectedKeys)];
            f.selectionChanged = false;
        });
    }
    /**
     * Update the selectedEntities and triggers this.update
     * @param entities
     */
    public updateEntities(entities: string[], htl_id: number[]): void {
        this.selectedEntities = entities;
        this.selectedHtlId = htl_id;
        //Check if allowed entities
        this.isAllowed = checkAllowedHtl(this.allowedHtlIds, this.selectedHtlId, this.id);
        this.update('entities');
    }
    public getOption(label: string): DashboarOption {
        return this.options[label];
    }
    public changeSliderPageView(): void {
        this.on_slide.next(true);
    }
    public _wSlideView = false;
    public setSlideWidgetView(value: boolean): void {
        this._wSlideView = value;
    }
    public init(data: any): void {
        this.id = data.id;
        this.title = data.title;
        this.sections = data.sections;
        this.options = data.options;
        this.view = data.view;
        this.staticData = data.staticData;
        this.allowedHtlIds = data.allowedHtlIds;
        this.isAllowed = data.isAllowed;
        this.on_init.next(true);
        this.eventManagerService.broadcast({
            name: 'dashboardSwitch',
            data: this.allowedHtlIds
        });
    }
    public update(trigger?: string): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            const _trigger = trigger !== undefined ? trigger : true;
            if (this.url && (this.data === null || !this.queryOnce)) {
                this.serverService.post(
                    this.url + '/index',
                    {
                        options: this.selectedOptions,
                        filters: this.selectedFilters,
                        entities: this.selectedEntities,
                        htl_id: this.selectedHtlId
                    },
                    (data) => {
                        this.options_title = data.options_title;
                        this.data = data.data;
                        this.title = data.title;
                        this.on_change.next(_trigger);
                        resolve();
                    },
                    () => {
                        reject();
                    },
                    this.ngUnsubscribe
                );
            } else {
                this.on_change.next(_trigger);
                resolve();
            }
        });
    }
    public getSelectedEntitiesString(addquotes: boolean): string {
        if (addquotes) {
            return this.selectedEntities.map((x) => "'" + x + "'").toString();
        } else {
            return this.selectedEntities.map((x) => x).toString();
        }
    }

    public createWPMatrice():void{
        console.log('createWPMatrice')
        const matriceData: MatriceExcel[]=[];
        this._widgets.forEach(_w => {
            if (_w.widgetRef !== "OrmaEventsWidget"){
                matriceData.push(_w.ormaMatriceToExport)
            }
            
        })
        console.log('matriceData', matriceData)
        this.ormaservice.generateWPExcelMatrice(matriceData);
    }
}
export interface DashboarOption {
    mandatory: boolean;
    name: string;
    title: string;
    selected: any;
    type: string;
    queryOnce: boolean;
    options: (DashboardOptionId | DashboardOptionValue)[];
    renderer: string;
    invisible: boolean;
    staticData: any[];
}
export interface DashboardOptionId {
    id: string | number;
    label: string;
}
export interface DashboardOptionValue {
    label: string;
    value: number;
}
export class MatriceExcel {
    data: any[]
    sheetName: string
}