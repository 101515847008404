import { Injectable } from '@angular/core';

import { ServerService } from './server.service';
import { EntityService } from './entity.service';
import { ffn } from '../../core/utils/ffn';

@Injectable()
export class FinanceService {
    public curr = 'LCE';

    constructor(
        public serverService: ServerService,
        public entityService: EntityService
    ) {}

    setWidgetFinData(data): any[] {
        let ddata = data;
        const financeData: any[] = ddata
            .filter(
                (f) =>
                    f.SCENARIO == 'ACT' &&
                    f.YEARID == new Date().getFullYear() &&
                    f.MONTHID <= new Date().getMonth() + 1
            )
            .map((m) =>
                (<any>Object).fromEntries(
                    ['SCENARIO', 'MONTHID', 'YEARID', 'CUSTOMACCOUNT', 'DEV', 'EUR'].map(
                        (item) => [item, m[item]]
                    )
                )
            );
        financeData.map((m) => {
            m.SCENARIO = 'SAGE';
        });
        ddata = ddata.concat(financeData);

        const widgetFinData = ddata.filter((f) => f.SCENARIO != null);
        widgetFinData
            .filter(
                (f) =>
                    f.SCENARIO == 'ACT' &&
                    f.YEARID == new Date().getFullYear() &&
                    f.MONTHID > new Date().getMonth()
            )
            .map((m) => {
                (m.EUR = 0), (m.DEV = 0);
            });

        return widgetFinData;
    }
    async getWidgetFinData(findata, reportset, periodid, scenarios): Promise<any[]> {
        const reportFinData = await this.getMappedData(
            findata,
            reportset,
            periodid,
            scenarios
        );

        const presetDdata = await this.getPresetDdata(reportFinData, reportset);
        const ddata = await this.getDdata(
            reportset,
            periodid,
            presetDdata,
            reportFinData
        );

        return ddata;
    }

    getMappedData(findata, reportset, periodid, scenarios): any[] {
        const report = reportset;
        const curr = this.curr;
        // setting scenarios upon attributes
        const scenario: any[] = scenarios;

        let customaccounts = JSON.stringify(report.map((m) => m.RETRIEVEID))
            .replace(/([*"+\/\-)(])/g, ',')
            .split(/,/)
            .filter((f) => f != '' && f != '0_0');
        const revPercentAccounts = JSON.stringify(
            report.filter((f) => f.REVPERCENT != null).map((m) => m.REVPERCENT)
        )
            .replace(/([*"+\/\-)(])/g, ',')
            .split(/,/)
            .filter((f) => f != '' && f != '0_0');
        //console.log('revPercentAccounts', revPercentAccounts)
        customaccounts = customaccounts.concat(revPercentAccounts);
        // filtering datas
        let ddata: any[] = [];
        scenario.forEach((s) => {
            let dd = [];
            const test: any = {};
            dd = findata.filter(
                (f) =>
                    customaccounts.includes(f.CUSTOMACCOUNT) &&
                    f.SCENARIO + f.YEARID == s.scnid
            );
            test[s.scn] = dd.map((obj) => ({ ...obj }));
            test[s.scn].map((m) => {
                m.SCN = s.scn;
            });
            ddata = ddata.concat(test[s.scn]);
        });

        const ytddata = ffn.groupAndSum(
            ddata.filter((f) => f.MONTHID <= periodid),
            ['CUSTOMACCOUNT', 'SCENARIO', 'DEVISE', 'SCN', 'YEARID'],
            ['EUR', 'DEV']
        );
        ytddata.map((m) => (m.MONTHID = 'YTD'));
        const fyrdata = ffn.groupAndSum(
            ddata.filter((f) => f.MONTHID <= 12),
            ['CUSTOMACCOUNT', 'SCENARIO', 'DEVISE', 'SCN', 'YEARID'],
            ['EUR', 'DEV']
        );
        fyrdata.map((m) => (m.MONTHID = 'FYR'));
        const ytgdata = ffn.groupAndSum(
            ddata.filter((f) => f.MONTHID > periodid),
            ['CUSTOMACCOUNT', 'SCENARIO', 'DEVISE', 'SCN', 'YEARID'],
            ['EUR', 'DEV']
        );
        ytgdata.map((m) => (m.MONTHID = 'YTG'));
        const qtr1data = ffn.groupAndSum(
            ddata.filter((f) => [1, 2, 3].includes(f.MONTHID)),
            ['CUSTOMACCOUNT', 'SCENARIO', 'DEVISE', 'SCN', 'YEARID'],
            ['EUR', 'DEV']
        );
        qtr1data.map((m) => (m.MONTHID = 'QTR1'));
        const qtr2data = ffn.groupAndSum(
            ddata.filter((f) => [4, 5, 6].includes(f.MONTHID)),
            ['CUSTOMACCOUNT', 'SCENARIO', 'DEVISE', 'SCN', 'YEARID'],
            ['EUR', 'DEV']
        );
        qtr2data.map((m) => (m.MONTHID = 'QTR2'));
        const qtr3data = ffn.groupAndSum(
            ddata.filter((f) => [7, 8, 9].includes(f.MONTHID)),
            ['CUSTOMACCOUNT', 'SCENARIO', 'DEVISE', 'SCN', 'YEARID'],
            ['EUR', 'DEV']
        );
        qtr3data.map((m) => (m.MONTHID = 'QTR3'));
        const qtr4data = ffn.groupAndSum(
            ddata.filter((f) => [10, 11, 12].includes(f.MONTHID)),
            ['CUSTOMACCOUNT', 'SCENARIO', 'DEVISE', 'SCN', 'YEARID'],
            ['EUR', 'DEV']
        );
        qtr4data.map((m) => (m.MONTHID = 'QTR4'));
        ddata = ddata
            .concat(ytddata)
            .concat(fyrdata)
            .concat(ytgdata)
            .concat(qtr1data)
            .concat(qtr2data)
            .concat(qtr3data)
            .concat(qtr4data);

        // summarize data per MONTH + CUSTOMACCOUNT split by EUR & LCE
        const dataSum = ffn.getData(
            ddata,
            { field: 'SCN', ref: '' },
            '',
            [],
            ['EUR', 'DEV'],
            ['MONTHID', 'CUSTOMACCOUNT', 'DEVISE']
        );

        let dataEur = dataSum.filter((f) => f.KPI == 'EUR');
        dataEur = dataEur.map((m) => ({
            DEVISE: m.DEVISE,
            CSTMONTHID: m.CUSTOMACCOUNT + '_' + m.MONTHID + '_' + m.DEVISE,
            CUSTOMACCOUNT: m.CUSTOMACCOUNT,
            MONTHID: m.MONTHID,
            SCNA_EUR: ffn.nOn(m.SCNA),
            SCNB_EUR: ffn.nOn(m.SCNB),
            SCNC_EUR: ffn.nOn(m.SCNC),
            SCND_EUR: ffn.nOn(m.SCND)
        }));
        let dataLce = dataSum.filter((f) => f.KPI == 'DEV');

        dataLce = dataLce.map((m) => ({
            DEVISE: m.DEVISE,
            CSTMONTHID: m.CUSTOMACCOUNT + '_' + m.MONTHID + '_' + m.DEVISE,
            SCNA_LCE: ffn.nOn(m.SCNA),
            SCNB_LCE: ffn.nOn(m.SCNB),
            SCNC_LCE: ffn.nOn(m.SCNC),
            SCND_LCE: ffn.nOn(m.SCND)
        }));

        // Merging EUR + LCE
        const rawdata = ffn.mergeById(dataEur, dataLce, 'CSTMONTHID', 'MONTHID');

        rawdata.map(
            (m) => (
                (m.VAL_SCNAB =
                    m.SCNB_LCE && m.SCNB_LCE != 0
                        ? ffn.nOn(
                              m['SCNB_' + curr] +
                                  ((m.SCNA_LCE - m.SCNB_LCE) / m.SCNB_LCE) *
                                      m['SCNB_' + curr]
                          )
                        : m['SCNA_' + curr]),
                (m.VAL_SCNAC =
                    m.SCNC_LCE && m.SCNC_LCE != 0
                        ? ffn.nOn(
                              m['SCNC_' + curr] +
                                  ((m.SCNA_LCE - m.SCNC_LCE) / m.SCNC_LCE) *
                                      m['SCNC_' + curr]
                          )
                        : m['SCNA_' + curr]),
                (m.VAL_SCNAD =
                    m.SCND_LCE && m.SCND_LCE != 0
                        ? ffn.nOn(
                              m['SCND_' + curr] +
                                  ((m.SCNA_LCE - m.SCND_LCE) / m.SCND_LCE) *
                                      m['SCND_' + curr]
                          )
                        : m['SCNA_' + curr])
            )
        );

        // Transpose Account to Colums to perform Formulas Calculation
        const transposeAccount = ffn.getData(
            rawdata,
            { field: 'CUSTOMACCOUNT', ref: '' },
            '',
            [],
            [
                'SCNA_EUR',
                'SCNB_EUR',
                'SCNC_EUR',
                'SCND_EUR',
                'SCNA_LCE',
                'SCNB_LCE',
                'SCNC_LCE',
                'SCND_LCE',
                'VAL_SCNAB',
                'VAL_SCNAC',
                'VAL_SCNAD'
            ],
            ['MONTHID']
        );
        // get Distinct Accounts + Formulas out of Report

        // SET UNIQUE FORMULAS
        let allformulas = report
            .filter((f) => f.FORMULA != undefined)
            .map((m) => ({
                field: m.FORMULA.replace(/ /g, ''),
                formula: m.FORMULA.replace(/ /g, '')
            }));
        let allRevPercentformulas = report
            .filter((f) => f.REVPERCENT != undefined)
            .map((m) => ({
                field: m.REVPERCENT.replace(/ /g, ''),
                formula: m.REVPERCENT.replace(/ /g, '')
            }));
        allRevPercentformulas = allRevPercentformulas.filter(
            (f) => !report.map((m) => m.CUSTOMACCOUNT).includes(f.field)
        );
        allformulas = allformulas.concat(allRevPercentformulas);

        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        const uniqueBy = (x, f) =>
            Object.values(x.reduce((a, b) => ((a[f(b)] = b), a), {}));
        const formulas = uniqueBy(
            allformulas.map((m) =>
                (<any>Object).fromEntries(
                    ['field', 'formula'].map((item) => [item, m[item]])
                )
            ),
            (v) => v.formula
        );
        // setting variable des valeurs d'aggregation distinctes

        const accounts = ffn.getUniqueItems(rawdata, 'CUSTOMACCOUNT');

        // running Calculated Fields
        const dataCalculated = ffn.getData(
            transposeAccount,
            { field: 'KPI', ref: '' },
            '',
            [],
            accounts,
            ['MONTHID'],
            formulas
        );

        // Assign RetrieveId Field to dataCalculated
        dataCalculated.map((m) => {
            m.RETRIEVEID = m.KPI;
        });

        return dataCalculated;

        // Merge results to Report for Output
    }

    getPresetDdata(data, reportset): any[] {
        const report = reportset;
        const curr = this.curr;
        const fyrdata = ffn.groupAndSum(
            data.filter((f) => f.MONTHID == 'FYR'),
            ['KPI', 'DEVISE'],
            [
                'SCNA_EUR',
                'SCNB_EUR',
                'SCNC_EUR',
                'SCND_EUR',
                'SCNA_LCE',
                'SCNB_LCE',
                'SCNC_LCE',
                'SCND_LCE',
                'VAL_SCNAB',
                'VAL_SCNAC',
                'VAL_SCNAD'
            ]
        );

        //sconsole.log('FYR FINANCE DATA **********', fyrdata)
        let preset = fyrdata.map((m) => ({
            CUSTOMACCOUNT: m.KPI,
            FYR_SCA: m['SCNA_' + curr],
            FYR_SCB: m['SCNB_' + curr],
            FYR_SCC: m['SCNC_' + curr],
            FYR_SCD: m['SCND_' + curr],
            FYR_OPVARAB: ffn.nOn(m.VAL_SCNAB - m['SCNB_' + curr]),
            FYR_OPVARAC: ffn.nOn(m.VAL_SCNAC - m['SCNC_' + curr]),
            FYR_OPVARAD: ffn.nOn(m.VAL_SCNAD - m['SCND_' + curr]),
            FYR_GROWTHAB: ffn.nOn((m.VAL_SCNAB - m['SCNB_' + curr]) / m['SCNB_' + curr]),
            FYR_GROWTHAC: ffn.nOn((m.VAL_SCNAC - m['SCNC_' + curr]) / m['SCNC_' + curr]),
            FYR_GROWTHAD: ffn.nOn((m.VAL_SCNAD - m['SCND_' + curr]) / m['SCND_' + curr]),
            FYRREVPERCENT_SCA: ffn.nOn(
                m['SCNA_' + curr] /
                    ffn.getRevPercent(report, fyrdata, m.KPI, 'SCNA_' + curr)
            ),
            FYRREVPERCENT_SCB: ffn.nOn(
                m['SCNB_' + curr] /
                    ffn.getRevPercent(report, fyrdata, m.KPI, 'SCNB_' + curr)
            ),
            FYRREVPERCENT_SCC: ffn.nOn(
                m['SCNC_' + curr] /
                    ffn.getRevPercent(report, fyrdata, m.KPI, 'SCNC_' + curr)
            ),
            FYRREVPERCENT_SCD: ffn.nOn(
                m['SCND_' + curr] /
                    ffn.getRevPercent(report, fyrdata, m.KPI, 'SCND_' + curr)
            ),
            FYRRATIO_SCA: ffn.nOn(
                m['SCNA_' + curr] /
                    ffn.getRevPercount(report, fyrdata, m.KPI, 'SCNA_' + curr)
            ),
            FYRRATIO_SCB: ffn.nOn(
                m['SCNB_' + curr] /
                    ffn.getRevPercount(report, fyrdata, m.KPI, 'SCNB_' + curr)
            ),
            FYRRATIO_SCC: ffn.nOn(
                m['SCNC_' + curr] /
                    ffn.getRevPercount(report, fyrdata, m.KPI, 'SCNC_' + curr)
            ),
            FYRRATIO_SCD: ffn.nOn(
                m['SCND_' + curr] /
                    ffn.getRevPercount(report, fyrdata, m.KPI, 'SCND_' + curr)
            )
        }));

        for (let mId = 1; mId <= 12; mId++) {
            preset = ffn.mergeById(
                preset,
                this.setNewMonthFeed(report, data, mId, this.curr),
                'CUSTOMACCOUNT',
                'CUSTOMACCOUNT'
            );
        }
        for (let qId = 1; qId <= 4; qId++) {
            preset = ffn.mergeById(
                preset,
                this.setQuarterFeed(data, qId, this.curr, reportset),
                'CUSTOMACCOUNT',
                'CUSTOMACCOUNT'
            );
        }
        
        return preset;
    }

    mergeScenarios(mtddata, ytddata, ytgdata, mtd1data, mtd2data, mtd3data): any[] {
        return mtddata
            .map((itm) => ({
                ...ytddata.find(
                    (item) => item['CUSTOMACCOUNT'] === itm['CUSTOMACCOUNT'] && item
                ),
                ...ytgdata.find(
                    (item) => item['CUSTOMACCOUNT'] === itm['CUSTOMACCOUNT'] && item
                ),
                ...mtd1data.find(
                    (item) => item['CUSTOMACCOUNT'] === itm['CUSTOMACCOUNT'] && item
                ),
                ...mtd2data.find(
                    (item) => item['CUSTOMACCOUNT'] === itm['CUSTOMACCOUNT'] && item
                ),
                ...mtd3data.find(
                    (item) => item['CUSTOMACCOUNT'] === itm['CUSTOMACCOUNT'] && item
                ),
                /*  ...qtr1data.find((item) => (item['CUSTOMACCOUNT'] === itm['CUSTOMACCOUNT']) && item),
             ...qtr2data.find((item) => (item['CUSTOMACCOUNT'] === itm['CUSTOMACCOUNT']) && item),
             ...qtr3data.find((item) => (item['CUSTOMACCOUNT'] === itm['CUSTOMACCOUNT']) && item),
             ...qtr4data.find((item) => (item['CUSTOMACCOUNT'] === itm['CUSTOMACCOUNT']) && item), */
                ...itm
            }))
            .sort((a, b) => (a['CUSTOMACCOUNT'] < b['CUSTOMACCOUNT'] ? -1 : 1));
    }

    async getDdata(reportset, periodid, presetDdata, dataset): Promise<any[]> {
        // Define Attributes

        const report = reportset;

        const curr = this.curr;
        const monthid = periodid;
        const mtddata = dataset.filter((f) => f.MONTHID == monthid);
        const mtdmap = mtddata.map((m) => ({
            CUSTOMACCOUNT: m.KPI,
            MTD_SCA: ffn.nOn(m['SCNA_' + curr]),
            MTD_SCB: ffn.nOn(m['SCNB_' + curr]),
            MTD_SCC: ffn.nOn(m['SCNC_' + curr]),
            MTD_SCD: ffn.nOn(m['SCND_' + curr]),
            MTD_OPVARAB: ffn.nOn(m.VAL_SCNAB - m['SCNB_' + curr]),
            MTD_OPVARAC: ffn.nOn(m.VAL_SCNAC - m['SCNC_' + curr]),
            MTD_OPVARAD: ffn.nOn(m.VAL_SCNAD - m['SCND_' + curr]),
            MTD_GROWTHAB: ffn.nOn(
                (m.VAL_SCNAB - m['SCNB_' + curr]) / Math.abs(m['SCNB_' + curr])
            ),
            MTD_GROWTHAC: ffn.nOn(
                (m.VAL_SCNAC - m['SCNC_' + curr]) / Math.abs(m['SCNC_' + curr])
            ),
            MTD_GROWTHAD: ffn.nOn(
                (m.VAL_SCNAD - m['SCND_' + curr]) / Math.abs(m['SCND_' + curr])
            ),
            MTDREVPERCENT_SCA: ffn.nOn(
                m['SCNA_' + curr] /
                    ffn.getRevPercent(report, mtddata, m.KPI, 'SCNA_' + curr)
            ),
            MTDREVPERCENT_SCB: ffn.nOn(
                m['SCNB_' + curr] /
                    ffn.getRevPercent(report, mtddata, m.KPI, 'SCNB_' + curr)
            ),
            MTDREVPERCENT_SCC: ffn.nOn(
                m['SCNC_' + curr] /
                    ffn.getRevPercent(report, mtddata, m.KPI, 'SCNC_' + curr)
            ),
            MTDRATIO_SCA: ffn.nOn(
                (m['SCNA_' + curr] * (m.STAT == 0 ? 1 : 1000)) /
                    ffn.getRevPercount(report, mtddata, m.KPI, 'SCNA_' + curr)
            ),
            MTDRATIO_SCB: ffn.nOn(
                (m['SCNB_' + curr] * (m.STAT == 0 ? 1 : 1000)) /
                    ffn.getRevPercount(report, mtddata, m.KPI, 'SCNB_' + curr)
            ),
            MTDRATIO_SCC: ffn.nOn(
                (m['SCNC_' + curr] * (m.STAT == 0 ? 1 : 1000)) /
                    ffn.getRevPercount(report, mtddata, m.KPI, 'SCNC_' + curr)
            )
        }));

        const ytddata = ffn.groupAndSum(
            dataset.filter((f) => f.MONTHID == 'YTD'),
            ['KPI'],
            [
                'SCNA_EUR',
                'SCNB_EUR',
                'SCNC_EUR',
                'SCND_EUR',
                'SCNA_LCE',
                'SCNB_LCE',
                'SCNC_LCE',
                'SCND_LCE',
                'VAL_SCNAB',
                'VAL_SCNAC',
                'VAL_SCNAD'
            ]
        );
        const ytdmap = ytddata.map((m) => ({
            CUSTOMACCOUNT: m.KPI,
            YTD_SCA: m['SCNA_' + curr],
            YTD_SCB: m['SCNB_' + curr],
            YTD_SCC: m['SCNC_' + curr],
            YTD_SCD: m['SCND_' + curr],
            YTD_OPVARAB: ffn.nOn(m.VAL_SCNAB - m['SCNB_' + curr]),
            YTD_OPVARAC: ffn.nOn(m.VAL_SCNAC - m['SCNC_' + curr]),
            YTD_OPVARAD: ffn.nOn(m.VAL_SCNAD - m['SCND_' + curr]),
            YTD_GROWTHAB: ffn.nOn(
                (m.VAL_SCNAB - m['SCNB_' + curr]) / Math.abs(m['SCNB_' + curr])
            ),
            YTD_GROWTHAC: ffn.nOn(
                (m.VAL_SCNAC - m['SCNC_' + curr]) / Math.abs(m['SCNC_' + curr])
            ),
            YTD_GROWTHAD: ffn.nOn(
                (m.VAL_SCNAD - m['SCND_' + curr]) / Math.abs(m['SCND_' + curr])
            ),
            YTDREVPERCENT_SCA: ffn.nOn(
                m['SCNA_' + curr] /
                    ffn.getRevPercent(report, ytddata, m.KPI, 'SCNA_' + curr)
            ),
            YTDREVPERCENT_SCB: ffn.nOn(
                m['SCNB_' + curr] /
                    ffn.getRevPercent(report, ytddata, m.KPI, 'SCNB_' + curr)
            ),
            YTDREVPERCENT_SCC: ffn.nOn(
                m['SCNC_' + curr] /
                    ffn.getRevPercent(report, ytddata, m.KPI, 'SCNC_' + curr)
            ),
            YTDREVPERCENT_SCD: ffn.nOn(
                m['SCND_' + curr] /
                    ffn.getRevPercent(report, ytddata, m.KPI, 'SCND_' + curr)
            ),
            YTDRATIO_SCA: ffn.nOn(
                m['SCNA_' + curr] /
                    ffn.getRevPercount(report, ytddata, m.KPI, 'SCNA_' + curr)
            ),
            YTDRATIO_SCB: ffn.nOn(
                m['SCNB_' + curr] /
                    ffn.getRevPercount(report, ytddata, m.KPI, 'SCNB_' + curr)
            ),
            YTDRATIO_SCC: ffn.nOn(
                m['SCNC_' + curr] /
                    ffn.getRevPercount(report, ytddata, m.KPI, 'SCNC_' + curr)
            ),
            YTDRATIO_SCD: ffn.nOn(
                m['SCND_' + curr] /
                    ffn.getRevPercount(report, ytddata, m.KPI, 'SCND_' + curr)
            )
        }));

        const ytgdata = ffn.groupAndSum(
            dataset.filter((f) => f.MONTHID == 'YTG'),
            ['KPI'],
            [
                'SCNA_EUR',
                'SCNB_EUR',
                'SCNC_EUR',
                'SCND_EUR',
                'SCNA_LCE',
                'SCNB_LCE',
                'SCNC_LCE',
                'SCND_LCE',
                'VAL_SCNAB',
                'VAL_SCNAC',
                'VAL_SCNAD'
            ]
        );
        const ytgmap = ytgdata.map((m) => ({
            CUSTOMACCOUNT: m.KPI,
            YTG_SCA: m['SCNA_' + curr],
            YTG_SCB: m['SCNB_' + curr],
            YTG_SCC: m['SCNC_' + curr],
            YTG_SCD: m['SCND_' + curr],
            YTG_OPVARAB: ffn.nOn(m.VAL_SCNAB - m['SCNB_' + curr]),
            YTG_OPVARAC: ffn.nOn(m.VAL_SCNAC - m['SCNC_' + curr]),
            YTG_OPVARAD: ffn.nOn(m.VAL_SCNAD - m['SCND_' + curr]),
            YTG_GROWTHAB: ffn.nOn(
                (m.VAL_SCNAB - m['SCNB_' + curr]) / Math.abs(m['SCNB_' + curr])
            ),
            YTG_GROWTHAC: ffn.nOn(
                (m.VAL_SCNAC - m['SCNC_' + curr]) / Math.abs(m['SCNC_' + curr])
            ),
            YTG_GROWTHAD: ffn.nOn(
                (m.VAL_SCNAD - m['SCND_' + curr]) / Math.abs(m['SCND_' + curr])
            ),
            YTGREVPERCENT_SCA: ffn.nOn(
                m['SCNA_' + curr] /
                    ffn.getRevPercent(report, ytgdata, m.KPI, 'SCNA_' + curr)
            ),
            YTGREVPERCENT_SCB: ffn.nOn(
                m['SCNB_' + curr] /
                    ffn.getRevPercent(report, ytgdata, m.KPI, 'SCNB_' + curr)
            ),
            YTGREVPERCENT_SCC: ffn.nOn(
                m['SCNC_' + curr] /
                    ffn.getRevPercent(report, ytgdata, m.KPI, 'SCNC_' + curr)
            ),
            YTGREVPERCENT_SCD: ffn.nOn(
                m['SCND_' + curr] /
                    ffn.getRevPercent(report, ytgdata, m.KPI, 'SCND_' + curr)
            ),
            YTGRATIO_SCA: ffn.nOn(
                m['SCNA_' + curr] /
                    ffn.getRevPercount(report, ytgdata, m.KPI, 'SCNA_' + curr)
            ),
            YTGRATIO_SCB: ffn.nOn(
                m['SCNB_' + curr] /
                    ffn.getRevPercount(report, ytgdata, m.KPI, 'SCNB_' + curr)
            ),
            YTGRATIO_SCC: ffn.nOn(
                m['SCNC_' + curr] /
                    ffn.getRevPercount(report, ytgdata, m.KPI, 'SCNC_' + curr)
            ),
            YTGRATIO_SCD: ffn.nOn(
                m['SCND_' + curr] /
                    ffn.getRevPercount(report, ytgdata, m.KPI, 'SCND_' + curr)
            )
        }));

        const mtd1data = dataset.filter((f) => f.MONTHID == monthid + 1);
        const mtd1map = mtd1data.map((m) => ({
            CUSTOMACCOUNT: m.KPI,
            MTD1_SCA: m['SCNA_' + curr],
            MTD1_SCB: m['SCNB_' + curr],
            MTD1_SCC: m['SCNC_' + curr],
            MTD1_SCD: m['SCND_' + curr],
            MTD1_OPVARAB: ffn.nOn(m.VAL_SCNAB - m['SCNB_' + curr]),
            MTD1OPVARAC: ffn.nOn(m.VAL_SCNAC - m['SCNC_' + curr]),
            MTD1_OPVARAD: ffn.nOn(m.VAL_SCNAD - m['SCND_' + curr]),
            MTD1_GROWTHAB: ffn.nOn(
                (m.VAL_SCNAB - m['SCNB_' + curr]) / Math.abs(m['SCNB_' + curr])
            ),
            MTD1_GROWTHAC: ffn.nOn(
                (m.VAL_SCNAC - m['SCNC_' + curr]) / Math.abs(m['SCNC_' + curr])
            ),
            MTD1_GROWTHAD: ffn.nOn(
                (m.VAL_SCNAD - m['SCND_' + curr]) / Math.abs(m['SCND_' + curr])
            ),
            MTD1REVPERCENT_SCA: ffn.nOn(
                m['SCNA_' + curr] /
                    ffn.getRevPercent(report, mtd1data, m.KPI, 'SCNA_' + curr)
            ),
            MTD1REVPERCENT_SCB: ffn.nOn(
                m['SCNB_' + curr] /
                    ffn.getRevPercent(report, mtd1data, m.KPI, 'SCNB_' + curr)
            ),
            MTD1REVPERCENT_SCC: ffn.nOn(
                m['SCNC_' + curr] /
                    ffn.getRevPercent(report, mtd1data, m.KPI, 'SCNC_' + curr)
            ),
            MTD1REVPERCENT_SCD: ffn.nOn(
                m['SCND_' + curr] /
                    ffn.getRevPercent(report, mtd1data, m.KPI, 'SCND_' + curr)
            ),
            MTD1RATIO_SCA: ffn.nOn(
                m['SCNA_' + curr] /
                    ffn.getRevPercount(report, mtd1data, m.KPI, 'SCNA_' + curr)
            ),
            MTD1RATIO_SCB: ffn.nOn(
                m['SCNB_' + curr] /
                    ffn.getRevPercount(report, mtd1data, m.KPI, 'SCNB_' + curr)
            ),
            MTD1RATIO_SCC: ffn.nOn(
                m['SCNC_' + curr] /
                    ffn.getRevPercount(report, mtd1data, m.KPI, 'SCNC_' + curr)
            ),
            MTD1RATIO_SCD: ffn.nOn(
                m['SCND_' + curr] /
                    ffn.getRevPercount(report, mtd1data, m.KPI, 'SCND_' + curr)
            )
        }));

        const mtd2data = dataset.filter((f) => f.MONTHID == monthid + 2);
        const mtd2map = mtd2data.map((m) => ({
            CUSTOMACCOUNT: m.KPI,
            MTD2_SCA: m['SCNA_' + curr],
            MTD2_SCB: m['SCNB_' + curr],
            MTD2_SCC: m['SCNC_' + curr],
            MTD2_SCD: m['SCND_' + curr],
            MTD2_OPVARAB: ffn.nOn(m.VAL_SCNAB - m['SCNB_' + curr]),
            MTD2_OPVARAC: ffn.nOn(m.VAL_SCNAC - m['SCNC_' + curr]),
            MTD2_OPVARAD: ffn.nOn(m.VAL_SCNAD - m['SCND_' + curr]),
            MTD2_GROWTHAB: ffn.nOn(
                (m.VAL_SCNAB - m['SCNB_' + curr]) / Math.abs(m['SCNB_' + curr])
            ),
            MTD2_GROWTHAC: ffn.nOn(
                (m.VAL_SCNAC - m['SCNC_' + curr]) / Math.abs(m['SCNC_' + curr])
            ),
            MTD2_GROWTHAD: ffn.nOn(
                (m.VAL_SCNAD - m['SCND_' + curr]) / Math.abs(m['SCND_' + curr])
            ),
            MTD2REVPERCENT_SCA: ffn.nOn(
                m['SCNA_' + curr] /
                    ffn.getRevPercent(report, mtd2data, m.KPI, 'SCNA_' + curr)
            ),
            MTD2REVPERCENT_SCB: ffn.nOn(
                m['SCNB_' + curr] /
                    ffn.getRevPercent(report, mtd2data, m.KPI, 'SCNB_' + curr)
            ),
            MTD2REVPERCENT_SCC: ffn.nOn(
                m['SCNC_' + curr] /
                    ffn.getRevPercent(report, mtd2data, m.KPI, 'SCNC_' + curr)
            ),
            MTD2RATIO_SCA: ffn.nOn(
                m['SCNA_' + curr] /
                    ffn.getRevPercount(report, mtd2data, m.KPI, 'SCNA_' + curr)
            ),
            MTD2RATIO_SCB: ffn.nOn(
                m['SCNB_' + curr] /
                    ffn.getRevPercount(report, mtd2data, m.KPI, 'SCNB_' + curr)
            ),
            MTD2RATIO_SCC: ffn.nOn(
                m['SCNC_' + curr] /
                    ffn.getRevPercount(report, mtd2data, m.KPI, 'SCNC_' + curr)
            )
        }));

        const mtd3data = dataset.filter((f) => f.MONTHID == monthid + 3);
        const mtd3map = mtd3data.map((m) => ({
            CUSTOMACCOUNT: m.KPI,
            MTD3_SCA: m['SCNA_' + curr],
            MTD3_SCB: m['SCNB_' + curr],
            MTD3_SCC: m['SCNC_' + curr],
            MTD3_SCD: m['SCND_' + curr],
            MTD3_OPVARAB: ffn.nOn(m.VAL_SCNAB - m['SCNB_' + curr]),
            MTD3_OPVARAC: ffn.nOn(m.VAL_SCNAC - m['SCNC_' + curr]),
            MTD3_OPVARAD: ffn.nOn(m.VAL_SCNAD - m['SCND_' + curr]),
            MTD3_GROWTHAB: ffn.nOn(
                (m.VAL_SCNAB - m['SCNB_' + curr]) / Math.abs(m['SCNB_' + curr])
            ),
            MTD3_GROWTHAC: ffn.nOn(
                (m.VAL_SCNAC - m['SCNC_' + curr]) / Math.abs(m['SCNC_' + curr])
            ),
            MTD3_GROWTHAD: ffn.nOn(
                (m.VAL_SCNAD - m['SCND_' + curr]) / Math.abs(m['SCND_' + curr])
            ),
            MTD3REVPERCENT_SCA: ffn.nOn(
                m['SCNA_' + curr] /
                    ffn.getRevPercent(report, mtd3data, m.KPI, 'SCNA_' + curr)
            ),
            MTD3REVPERCENT_SCB: ffn.nOn(
                m['SCNB_' + curr] /
                    ffn.getRevPercent(report, mtd3data, m.KPI, 'SCNB_' + curr)
            ),
            MTD3REVPERCENT_SCC: ffn.nOn(
                m['SCNC_' + curr] /
                    ffn.getRevPercent(report, mtd3data, m.KPI, 'SCNC_' + curr)
            ),
            MTD3RATIO_SCA: ffn.nOn(
                m['SCNA_' + curr] /
                    ffn.getRevPercount(report, mtd3data, m.KPI, 'SCNA_' + curr)
            ),
            MTD3RATIO_SCB: ffn.nOn(
                m['SCNB_' + curr] /
                    ffn.getRevPercount(report, mtd3data, m.KPI, 'SCNB_' + curr)
            ),
            MTD3RATIO_SCC: ffn.nOn(
                m['SCNC_' + curr] /
                    ffn.getRevPercount(report, mtd3data, m.KPI, 'SCNC_' + curr)
            )
        }));

        let results: any[] = this.mergeScenarios(
            mtdmap,
            ytdmap,
            ytgmap,
            mtd1map,
            mtd2map,
            mtd3map
        );
        results = ffn.mergeById(results, presetDdata, 'CUSTOMACCOUNT', 'CUSTOMACCOUNT');
        results.map((m) => {
            m.RETRIEVEID = m.CUSTOMACCOUNT;
        });

        // Merge results to Report for Output
        const ddata = ffn.mergeById(report, results, 'RETRIEVEID', 'ORDERID');

        return ddata;
    }

    setNewMonthFeed(report, data, monthid, curr): any[] {
        const months = [
            'JAN',
            'FEB',
            'MAR',
            'APR',
            'MAY',
            'JUN',
            'JUL',
            'AUG',
            'SEP',
            'OCT',
            'NOV',
            'DEC'
        ];

        return data
            .filter((f) => f.MONTHID == monthid)
            .map((m) => ({
                CUSTOMACCOUNT: m.KPI,
                [months[monthid - 1] + '_SCA']: ffn.nOn(m['SCNA_' + curr]),
                [months[monthid - 1] + '_SCB']: ffn.nOn(m['SCNB_' + curr]),
                [months[monthid - 1] + '_SCC']: ffn.nOn(m['SCNC_' + curr]),
                [months[monthid - 1] + '_SCD']: ffn.nOn(m['SCND_' + curr]),
                [months[monthid - 1] + '_OPVARAB']: ffn.nOn(
                    m.VAL_SCNAB - m['SCNB_' + curr]
                ),
                [months[monthid - 1] + '_OPVARAC']: ffn.nOn(
                    m.VAL_SCNAC - m['SCNC_' + curr]
                ),
                [months[monthid - 1] + '_OPVARAD']: ffn.nOn(
                    m.VAL_SCNAD - m['SCND_' + curr]
                ),
                [months[monthid - 1] + '_GROWTHAB']: ffn.nOn(
                    (m.VAL_SCNAB - m['SCNB_' + curr]) / Math.abs(m['SCNB_' + curr])
                ),
                [months[monthid - 1] + '_GROWTHAC']: ffn.nOn(
                    (m.VAL_SCNAC - m['SCNC_' + curr]) / Math.abs(m['SCNC_' + curr])
                ),
                [months[monthid - 1] + '_GROWTHAD']: ffn.nOn(
                    (m.VAL_SCNAD - m['SCND_' + curr]) / Math.abs(m['SCND_' + curr])
                )
            }));
    }
    setQuarterFeed(data, qId, curr, reportset): any[] {
        //console.log('setNewQuarterFeed',qId )
        const report = reportset;
        const qtrData = ffn.groupAndSum(
            data.filter((f) => f.MONTHID == 'QTR' + qId),
            ['KPI'],
            [
                'SCNA_EUR',
                'SCNB_EUR',
                'SCNC_EUR',
                'SCND_EUR',
                'SCNA_LCE',
                'SCNB_LCE',
                'SCNC_LCE',
                'SCND_LCE',
                'VAL_SCNAB',
                'VAL_SCNAC',
                'VAL_SCNAD'
            ]
        );
        const preset = qtrData.map((m) => ({
            CUSTOMACCOUNT: m.KPI,
            ['QTR' + qId + '_SCA']: m['SCNA_' + curr],
            ['QTR' + qId + '_SCB']: m['SCNB_' + curr],
            ['QTR' + qId + '_SCC']: m['SCNC_' + curr],
            ['QTR' + qId + '_SCD']: m['SCND_' + curr],
            ['QTR' + qId + '_OPVARAB']: ffn.nOn(m.VAL_SCNAB - m['SCNB_' + curr]),
            ['QTR' + qId + '_OPVARAC']: ffn.nOn(m.VAL_SCNAC - m['SCNC_' + curr]),
            ['QTR' + qId + '_OPVARAD']: ffn.nOn(m.VAL_SCNAD - m['SCND_' + curr]),
            ['QTR' + qId + '_GROWTHAB']: ffn.nOn(
                (m.VAL_SCNAB - m['SCNB_' + curr]) / Math.abs(m['SCNB_' + curr])
            ),
            ['QTR' + qId + '_GROWTHAC']: ffn.nOn(
                (m.VAL_SCNAC - m['SCNC_' + curr]) / Math.abs(m['SCNC_' + curr])
            ),
            ['QTR' + qId + '_GROWTHAD']: ffn.nOn(
                (m.VAL_SCNAD - m['SCND_' + curr]) / Math.abs(m['SCND_' + curr])
            ),
            ['QTR' + qId + 'REVPERCENT_SCA']: ffn.nOn(
                m['SCNA_' + curr] /
                    ffn.getRevPercent(report, qtrData, m.KPI, 'SCNA_' + curr)
            ),
            ['QTR' + qId + 'REVPERCENT_SCB']: ffn.nOn(
                m['SCNB_' + curr] /
                    ffn.getRevPercent(report, qtrData, m.KPI, 'SCNB_' + curr)
            ),
            ['QTR' + qId + 'REVPERCENT_SCC']: ffn.nOn(
                m['SCNC_' + curr] /
                    ffn.getRevPercent(report, qtrData, m.KPI, 'SCNC_' + curr)
            ),
            ['QTR' + qId + 'REVPERCENT_SCD']: ffn.nOn(
                m['SCND_' + curr] /
                    ffn.getRevPercent(report, qtrData, m.KPI, 'SCND_' + curr)
            ),
            ['QTR' + qId + 'RATIO_SCA']: ffn.nOn(
                m['SCNA_' + curr] /
                    ffn.getRevPercount(report, qtrData, m.KPI, 'SCNA_' + curr)
            ),
            ['QTR' + qId + 'RATIO_SCB']: ffn.nOn(
                m['SCNB_' + curr] /
                    ffn.getRevPercount(report, qtrData, m.KPI, 'SCNB_' + curr)
            ),
            ['QTR' + qId + 'RATIO_SCC']: ffn.nOn(
                m['SCNC_' + curr] /
                    ffn.getRevPercount(report, qtrData, m.KPI, 'SCNC_' + curr)
            ),
            ['QTR' + qId + 'RATIO_SCD']: ffn.nOn(
                m['SCND_' + curr] /
                    ffn.getRevPercount(report, qtrData, m.KPI, 'SCND_' + curr)
            )
        }));

        return preset;
    }
}
