import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    public mode: 'dark' | 'light' = 'light';
    public themeChanged$ = new Subject();

    private _defaultPalette = {
        dark: {
            accent: {
                100: '#f2e9cc',
                200: '#e5d399',
                300: '#d9bc66',
                400: '#cca633',
                500: '#bf9000',
                600: '#997300',
                700: '#735600',
                800: '#4c3a00',
                900: '#261d00'
            },
            red: {
                100: '#fcd9e2',
                200: '#f9b3c4',
                300: '#f78da7',
                400: '#f46789',
                500: '#f1416c',
                600: '#c13456',
                700: '#912741',
                800: '#601a2b',
                900: '#300d16'
            },
            green: {
                100: '#dcf5e7',
                200: '#b9ebd0',
                300: '#96e1b8',
                400: '#73d7a1',
                500: '#50cd89',
                600: '#40a46e',
                700: '#307b52',
                800: '#205237',
                900: '#10291b'
            },
            gray: {
                100: '#e5e5e5',
                200: '#cbcbcb',
                300: '#b1b1b1',
                400: '#979797',
                500: '#7d7d7d',
                600: '#646464',
                700: '#4b4b4b',
                800: '#323232',
                900: '#191919'
            },
            textColor: '#CCCCCC',
            axisColor: '#D9DADF',
            tooltipBackground: '#1e1e2d',
            tooltipBorder: '#1e1e2d',
            legendInactive: '#666666',
            radarLabelColor: '#ccc'
        },
        light: {
            red: {
                100: '#ffcccc',
                200: '#ff9999',
                300: '#ff6666',
                400: '#ff3333',
                500: '#ff0000',
                600: '#cc0000',
                700: '#990000',
                800: '#660000',
                900: '#330000'
            },
            green: {
                100: '#ccebcc',
                200: '#99d699',
                300: '#66c266',
                400: '#33ad33',
                500: '#009900',
                600: '#007a00',
                700: '#005c00',
                800: '#003d00',
                900: '#001f00'
            },
            gray: {
                100: '#e5e5e5',
                200: '#cbcbcb',
                300: '#b1b1b1',
                400: '#979797',
                500: '#7d7d7d',
                600: '#646464',
                700: '#4b4b4b',
                800: '#323232',
                900: '#191919'
            },
            accent: {
                100: '#f2e9cc',
                200: '#e5d399',
                300: '#d9bc66',
                400: '#cca633',
                500: '#bf9000',
                600: '#997300',
                700: '#735600',
                800: '#4c3a00',
                900: '#261d00'
            },
            axisColor: '#979797',
            tooltipBackground: '#fff',
            tooltipBorder: '#fff',
            textColor: '#575962',
            legendInactive: '#ebe6e6',
            radarLabelColor: '#575962'
        }
    };

    public isDarkTheme(): boolean {
        return this.mode === 'dark';
    }

    get defaultPalette(): any {
        if (this.mode === 'dark') {
            return this._defaultPalette.dark;
        } else {
            return this._defaultPalette.light;
        }
    }
}
