import { Injectable } from '@angular/core';
interface Script {
    src: string;
    loaded: boolean;
}
@Injectable({ providedIn: 'root' })
export class ScriptLoaderService {
    public _scripts: Script[] = [];
    /**
     * 'Lazy' load list of scripts
     */
    public loadScripts(tag: string, scripts: any[], loadOnce = false): Promise<any> {
        const _promises: any[] = [];
        scripts.forEach((script) =>
            _promises.push(this._loadScript(tag, script, loadOnce))
        );
        return Promise.all(_promises);
    }
    /**
     * 'Lazy' load a single script
     */
    private _loadScript(tag, src: string, loadOnce = false): Promise<any> {
        let _script = this._scripts[src];
        if (!_script) {
            _script = { src: src, loaded: false };
            this._scripts[src] = _script;
        }
        return new Promise((resolve, _reject) => {
            // resolve if already loaded
            if (!(_script.loaded && loadOnce)) {
                // load script tag
                const scriptTag = $('<script/>')
                    .attr('type', 'text/javascript')
                    .attr('src', this._scripts[src].src);
                $(tag).append(scriptTag); // [Deprecation] Synchronous XMLHttpRequest on the main thread is deprecated because of its detrimental effects to the end user's experience
                _script.loaded = true;
                window.dispatchEvent(new CustomEvent('ptupdate'));
            }
            resolve(_script);
        });
    }
}
