import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    private storage$: Subject<string> = new Subject<string>();

    constructor() {
        window.addEventListener('storage', (event: StorageEvent) => {
            if (event.storageArea === localStorage) {
                const _key: string = event.key ? event.key : '';
                this.storage$.next(_key);
            }
        });
    }

    public watchStorage(): Observable<string> {
        return this.storage$.asObservable();
    }
}
