import { Injectable, Input } from '@angular/core';
import { ServerService } from './server.service';
//import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { EntityService } from './entity.service';
import { UserService } from './user.service';

//import * as XLSX from 'xlsx';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Subject } from 'rxjs';

@Injectable()
export class OrmaService {
    public ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor(
        public serverservice: ServerService,
        public entityService: EntityService,
        private entityservice: EntityService,
        private userservice: UserService
    ) { }

generateWPExcelMatrice(matrices: MatriceExcel[]): void {

    // Create a new workbook

    const workbook = XLSX.utils.book_new();

    matrices.forEach((m: MatriceExcel) => {
        if (m.sheetName === 'ORS RESTRICTION UPDATE') {
            const worksheetData = [['Resort', 'Restriction Type', 'Restriction Value', 'Rate Category', 'Rate Code', 'Room Class', 'Room Type', 'begin date', 'end date', 'M', 'T', 'W', 'T', 'F', 'S', 'S']];
            m.data.forEach(lineData => {
                worksheetData.push([
                    lineData.resort,
                    lineData.libMatrice,
                    lineData.restrictionValue,
                    lineData.rateCategory,
                    lineData.rateCode,
                    lineData.roomClass,
                    lineData.roomType,
                    lineData.begin_date,
                    lineData.end_date,
                    lineData.Mon,
                    lineData.Tue,
                    lineData.Wed,
                    lineData.Thu,
                    lineData.Fri,
                    lineData.Sat,
                    lineData.Sun
                ]);
            })
            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            XLSX.utils.book_append_sheet(workbook, worksheet, m.sheetName);
            // UPDATE PULSE ORMA RESTRICTION TABLE 
            this.serverservice.post(
                'dashboard/orma-dashboard/setRestrictions',
                {
                    htl_id: this.entityservice.selectedHtlId[0],
                    restrictionData: m.data
                },
                (data: any) => {
                    console.log('DATA', data);
                    
                },
                (_error) => {
                    console.log('error');
                },
                this.ngUnsubscribe,
                true
            );
        }
        if (m.sheetName === 'ORS RATE STRATEGY UPDATE') {
            const worksheetData = [['Resort', 'Rate Category', 'Rate Code', 'begin date', 'end date', 'M', 'T', 'W', 'T', 'F', 'S', 'S', 'Condition value']];
            m.data.forEach(lineData => {
                worksheetData.push([
                    lineData.resort,
                    lineData.rateCategory,
                    lineData.rateCode,
                    lineData.begin_date,
                    lineData.end_date,
                    lineData.Mon,
                    lineData.Tue,
                    lineData.Wed,
                    lineData.Thu,
                    lineData.Fri,
                    lineData.Sat,
                    lineData.Sun,
                    lineData.rateStrategyValue,
                ]);
            })
            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'ORS RATE STRATEGY UPDATE');


        }
        if (m.sheetName === 'ORS OVERBOOKING UPDATE') {
            const worksheetData = [['Resort', 'Room Category', 'Begin Date', 'End Date', 'M', 'T', 'W', 'T', 'F', 'S', 'S', 'Overbooking Value']];
            m.data.forEach(lineData => {
                worksheetData.push([
                    lineData.resort,
                    lineData.room_category,
                    lineData.begin_date,
                    lineData.end_date,
                    lineData.Mon,
                    lineData.Tue,
                    lineData.Wed,
                    lineData.Thu,
                    lineData.Fri,
                    lineData.Sat,
                    lineData.Sun,
                    lineData.overBookingValue
                ]);
            })
            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            XLSX.utils.book_append_sheet(workbook, worksheet, m.sheetName);

        }
        if (m.sheetName === 'ORS RATE UPDATE') {
            const worksheet_ORSRATEUPDATE_Data = [['Resort', 'Rate codes', 'Room type', 'Begin date', 'End date', 'S', 'M', 'T', 'W', 'T', 'F', 'S', 'Pax1', 'Pax2', 'Pax3', 'Pax4', 'Pax5', 'ExtraA', 'ExtraC', 'Package']];
            m.data.forEach(lineData => {
                worksheet_ORSRATEUPDATE_Data.push([
                    lineData.resort,
                    lineData.rateCode,
                    lineData.roomType,
                    lineData.begin_date,
                    lineData.end_date,
                    lineData.Sun,
                    lineData.Mon,
                    lineData.Tue,
                    lineData.Wed,
                    lineData.Thu,
                    lineData.Fri,
                    lineData.Sat,
                    lineData.Pax1,
                    lineData.Pax2,
                    lineData.Pax3,
                    lineData.Pax4,
                    lineData.Pax5,
                    lineData.ExtraA,
                    lineData.ExtraC,
                    lineData.Package
                ]);
            })
            const worksheet_ORSRATEUPDATE = XLSX.utils.aoa_to_sheet(worksheet_ORSRATEUPDATE_Data);
            XLSX.utils.book_append_sheet(workbook, worksheet_ORSRATEUPDATE, 'ORS RATE UPDATE');




            const worksheet_ORSLINKEDRATEUPDATE_Data = [['Resort', 'Rate code', 'Room Types', 'Base Rate', 'Amount', '% or flat', 'Round', 'Packages', '', 'T', '', 'S']];
            m.data.forEach(lineData => {
                worksheet_ORSLINKEDRATEUPDATE_Data.push([
                    lineData.resort,
                    lineData.rateCategory,
                    lineData.rateCode,
                    lineData.begin_date,
                    lineData.end_date,
                    lineData.Mon,
                    lineData.Tue,
                    lineData.Wed,
                    lineData.Thu,
                    lineData.Fri,
                    lineData.Sat,
                    lineData.Sun,
                    lineData.rateStrategyValue,
                ]);
            })
            const worksheet_ORSLINKEDRATEUPDATE = XLSX.utils.aoa_to_sheet(worksheet_ORSLINKEDRATEUPDATE_Data);
            XLSX.utils.book_append_sheet(workbook, worksheet_ORSLINKEDRATEUPDATE, 'ORS LINKED RATE UPDATE');


            const worksheet_ORSADVDYNLINKEDRATEUPDATE_Data = [['Resort', 'Rate code', 'Room Types', 'begin date', 'end date', 'S', 'M', 'T', 'W', 'T', 'F', 'S', 'Base Rate', 'amount', '% or flat', 'Round', 'Packages']];
            m.data.forEach(lineData => {
                worksheet_ORSADVDYNLINKEDRATEUPDATE_Data.push([
                    lineData.resort,
                    lineData.rateCategory,
                    lineData.rateCode,
                    lineData.begin_date,
                    lineData.end_date,
                    lineData.Mon,
                    lineData.Tue,
                    lineData.Wed,
                    lineData.Thu,
                    lineData.Fri,
                    lineData.Sat,
                    lineData.Sun,
                    lineData.rateStrategyValue,
                ]);
            })
            const worksheet_ORSADVDYNLINKEDRATEUPDATE = XLSX.utils.aoa_to_sheet(worksheet_ORSADVDYNLINKEDRATEUPDATE_Data);
            XLSX.utils.book_append_sheet(workbook, worksheet_ORSADVDYNLINKEDRATEUPDATE, 'ORS ADVDYN LINKED RATE UPDATE');


        }
    })  
            const recipient_worksheetData = [['Report recipients list']];

    recipient_worksheetData.push([
        'rudolf.brink@radissonhotels.com'

    ]);

    const recipient_worksheet = XLSX.utils.aoa_to_sheet(recipient_worksheetData);
    XLSX.utils.book_append_sheet(workbook, recipient_worksheet, 'REPORT EMAIL LIST');


    const filePath = 'output.xlsx';
    XLSX.writeFile(workbook, filePath);



}
}

export class MatriceExcel {
    data: any[]
    sheetName: string
}