<div id="loading" *ngIf="show">
	<div  style="margin:auto; width: 160px; height: 40px; padding-top: 40px; vertical-align: middle">
		<div class="m-blockui">
			<span *ngIf="!text">
				Please wait...
			</span>
			<span *ngIf="text">
				{{text}}
			</span>
			<span>
				<div class="m-loader m-loader--brand"></div>
			</span>
		</div>
	</div>
</div>
