import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Helpers } from '../../helpers';
import { ThemeService } from 'app/core/services/theme.service';

declare let $: any;
declare let mApp: any;
declare let mLayout: any;
@Component({
    selector: 'app-dashboard-page',
    templateUrl: './dashboard.page.html',
    host: {
        '[style.height.%]': '100',
        class: 'mat-app-background'
    },
    encapsulation: ViewEncapsulation.None
})
export class DashboardPage implements OnInit, OnDestroy {
    private subscription: Subscription;
    public currentRoute: string;
    public currentYear: number = new Date().getFullYear();
    constructor(
        private router: Router,
        public themeService: ThemeService
    ) {}
    public ngOnInit(): void {
        this.currentRoute = this.router.url.split('#')[0];
        this.subscription = this.router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                const _split = route.url.split('#');
                if (this.currentRoute !== _split[0]) {
                    (<any>mLayout).closeMobileHorMenuOffcanvas();
                    if (!_split[1]) {
                        (<any>mApp).scrollTop();
                    }
                    Helpers.setLoading(true);
                    // (<any>$('[data-toggle="m - popover"]')).popover('hide'); // hide visible popover
                }
            }
            if (route instanceof NavigationEnd) {
                const _urlRoute = route.urlAfterRedirects.split('#')[0];
                if (this.currentRoute !== _urlRoute) {
                    Helpers.setLoading(false);
                    this.currentRoute = _urlRoute;
                    // content m-wrapper animation
                    const animation = 'm-animate-fade-in-up';
                    $('.m-wrapper')
                        .one(
                            'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',
                            function () {
                                $('.m-wrapper').removeClass(animation);
                            }
                        )
                        .removeClass(animation)
                        .addClass(animation);
                }
            }
        });
    }
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        $('#dashboard-page').find('*').off();
    }
}
