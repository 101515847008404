import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../shared/interfaces/UserInterface';
import { EntityService } from './entity.service';
import { ServerService } from './server.service';
import { Router } from '@angular/router';
/**
 * This service will call to serverService access/recover at the constructor
 * if 'x-access-token' its found.
 */
@Injectable({ providedIn: 'root' })
export class UserService {
    public user: User;
    public oAuthCode: string;
    public on_ready: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(
        public serverService: ServerService,
        public entity: EntityService,
        public router: Router
    ) {
        /* if (localStorage.getItem('x-access-token') !== null) {
            this.serverService.setSession(localStorage.getItem('x-access-token'));
            console.log('UserService constructor: x-access-token found --> recover');
            this.recoverAcccess();
        } */
    }
    /**
     * This method saves the user,
     * setSession on headers and localStorage (see ServerService),
     * and restart the EntityService variables (see EntityService).
     * If any component it's subscribed to userService.on_ready,
     * it will be trigger at the end of this method
     */
    public setData(user: User): void {
        this.user = user;

        Sentry.configureScope((scope) => {
            scope.setUser({ email: user.email, userid: user.user_id });
        });
        this.serverService.setSession(user.token);
        this.entity.init();

        let defaults = '';
        if (user.defaults && user.defaults.length > 0) {
            defaults = user.defaults[0].htl_ids; // first default
        }
        this.entity.setEntities(user.entities, defaults);
        this.on_ready.next(true);
    }

    recoverAcccess(url: string): Promise<boolean> {
        return new Promise<boolean>((resolve, _reject) => {
            this.serverService.post(
                'access/recover',
                { return_url: url },
                (data) => {
                    if (!data || data == 'Failed to Login / User does not exist') {
                        this.router.navigate(['/login']);
                        resolve(false);
                        return;
                    }
                    this.setData(data);
                    resolve(true);
                },
                (_error) => {
                    console.debug('recoverAcccess error: ' + _error);
                    console.debug('recoverAcccess error: ' + url);
                    console.debug('recoverAcccess error: ' + window.location.href);
                    localStorage.setItem('return_url', url);
                    this.router.navigate(['/login']);
                    resolve(false);
                }
            );
        });
    }

    public checkSSO(oAuthCode: string, _returnUrl: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!this.user) {
                this.serverService.post(
                    'access/oauth',
                    { code: oAuthCode, returnUrl: _returnUrl },
                    (data) => {
                        this.setData(data);
                        resolve(true);
                    },
                    (error) => {
                        console.error('oauth error: ' + error);
                        reject(false);
                    }
                );
            } else {
                resolve(true);
            }
        });
    }

    public checkAuthenticationAsPromise(url?: string): Promise<boolean> {
        console.log('called checkAuthenticationAsPromise!');
        const token = localStorage.getItem('x-access-token');
        if (token !== null && token != 'undefined' && this.user) {
            return new Promise<boolean>((resolve, _reject) => {
                resolve(true);
            });
        } else {
            return this.recoverAcccess(url);
        }
    }
}
