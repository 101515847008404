import { Subject } from 'rxjs';
import {
    NodeRows,
    rgbArray,
    rgbArrayDonuts,
    opsAreaMapping
} from './shared-constants.utils';
import { values } from 'lodash-es';
declare const $;
/**
 * value1 + value2
 * Check if null or undefined.
 * It's possible to obtain null or undefined as response if both values don't have value.
 */
export const incrementValue = (
    value1: number | string,
    value2: number | string
): number => {
    if (value2 !== undefined && value2 !== null) {
        const num1 = typeof value1 === 'string' ? parseFloat(value1) : value1;
        const num2 = typeof value2 === 'string' ? parseFloat(value2) : value2;
        value1 = num1 ? num1 + num2 : num2;
    }
    return value1 as number;
};
export const padWithZero = (num: number): string => (num < 10 ? `0${num}` : `${num}`);
/**
 * value1 - value2
 * Check if null or undefined.
 * It's possible to obtain null or undefined as response if both values don't have value.
 */
export const substractValue = (
    value1: number | string,
    value2: number | string
): number => {
    if (typeof value1 === 'string') {
        value1 = parseFloat(value1);
    }
    if (typeof value2 === 'string') {
        value2 = parseFloat(value2);
    }
    if (value2 !== undefined && value2 !== null) {
        value1 = value1 ? value1 - value2 : 0 - value2;
    }
    return value1 as number;
};

export const divideValue = (value1: number | string, value2: number | string): number => {
    if (typeof value1 === 'string') {
        value1 = parseFloat(value1);
    }
    if (typeof value2 === 'string') {
        value2 = parseFloat(value2);
    }
    if (value2 && !Number.isNaN(value2) && value1 !== undefined && value1 !== null) {
        return value1 / value2;
    }
    return null;
};

export const divideValueOrZero = (
    value1: number | string,
    value2: number | string
): number => {
    if (typeof value1 === 'string') {
        value1 = parseFloat(value1);
    }
    if (typeof value2 === 'string') {
        value2 = parseFloat(value2);
    }
    if (value2 && !Number.isNaN(value2) && value1 !== undefined && value1 !== null) {
        return value1 / value2;
    }
    return 0;
};

export const multiplyValue = (
    value1: number | string,
    value2: number | string
): number => {
    if (typeof value1 === 'string') {
        value1 = parseFloat(value1);
    }
    if (typeof value2 === 'string') {
        value2 = parseFloat(value2);
    }
    if (value2 && !Number.isNaN(value2) && value1 !== undefined && value1 !== null) {
        return value1 * value2;
    }
    return null;
};

export const calculateVariance = (
    value1: number | string,
    value2: number | string,
    multiplier = 100
): number[] => {
    // correct formula is (value1 - value2) / value2
    // value1 = new value
    // value2 = old value
    if (typeof value1 === 'string') {
        value1 = parseFloat(value1);
    }
    if (typeof value2 === 'string') {
        value2 = parseFloat(value2);
    }
    if (value2 != null) {
        const _chg = substractValue(value1, value2);
        const _chg_div = divideValue(_chg, Math.abs(value2));
        return _chg_div !== null ? [_chg, _chg_div * multiplier] : [_chg, 0];
    }
    return [null, null];
};

export const growthVar = (value1: number | string, value2: number | string): number => {
    if (typeof value1 === 'string') {
        value1 = parseFloat(value1);
    }
    if (typeof value2 === 'string') {
        value2 = parseFloat(value2);
    }
    if (value2 === null || (value2 === 0 && value1 !== null)) {
        return 1;
    } else {
        return value1 / value2 - 1;
    }
};

/**
 * Check equals for simple arrays
 */
export const arraysEqual = (arr1: any[], arr2: any[]): boolean => {
    if (!Array.isArray(arr2)) return false;
    if (arr2 && arr1.length !== arr2.length) return false;
    for (let i = arr1.length; i--; ) {
        if (arr1[i] !== arr2[i]) return false;
    }
    return true;
};
export const metricRanges = [
    { divider: 1e18, suffix: 'E' },
    { divider: 1e15, suffix: 'P' },
    { divider: 1e12, suffix: 'T' },
    { divider: 1e9, suffix: 'B' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'k' }
];
/**
 * Stringify the given number, set suffix by 'metricRanges' array
 */
export const stringifyNumberMetricSuffix = (
    n: number,
    round?: boolean,
    decimal?: number
): string => {
    const value = n > 0 ? n : -n;
    for (let i = 0; i < metricRanges.length; i++) {
        if (value >= metricRanges[i].divider) {
            let number = value / metricRanges[i].divider;
            if (round) {
                number = Math.round(number * 100) / 100;
            }
            const str =
                number.toFixed(decimal >= 0 ? decimal : 2) + metricRanges[i].suffix;
            return n >= 0 ? str : '-' + str;
        }
    }

    const number = round ? Math.round(value * 100) / 100 : value;
    const res = decimal >= 0 ? number.toFixed(decimal) : number.toString();
    return n >= 0 ? res : '-' + res;
};

/**
 * Return the full room type name based on the given room type abreviation
 */
export const returnFullRoomType = (roomType: string): string => {
    switch (roomType) {
        case 'P/D/E':
            return 'Premium/Deluxe/Executive';
        case 'A/R':
            return 'Appartements & Residences';
        case 'Suite':
            return 'Junior Suite';
        case 'Twin':
            return 'Standard Twin';
        case 'Double':
            return 'Standard Double';
        case 'Tri/Quad':
            return 'Standard Triple/Quadruple';
        case 'Suites':
            return 'Junior suites/Suites';
        case 'Apart':
            return 'Apartments';
        case 'Family':
            return 'Family Room';
        default:
            return roomType;
    }
};
export const colorSpan = (color): string =>
    '<span style="display: inline-block; margin-right: 5px; border-radius: 10px; width: 9px; height: 9px; background-color:' +
    color +
    '"></span>';
export const createdCellF1 = (td, cellData, _rowData, _row, _col): void => {
    if (cellData < 0) {
        $(td).addClass('red');
    }
};
export const createdCellF2 = (td, cellData, _rowData, _row, _col): void => {
    if (cellData > 0) {
        $(td).addClass('green');
    } else if (cellData < 0) {
        $(td).addClass('red');
    }
};
export const getValueFormatted = (data, decimal = 1): string => {
    if (data !== undefined && data !== null && isFinite(data)) {
        let _value = data >= 0 ? data : -data;
        _value = Number(_value.toFixed(decimal));
        _value = _value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        _value = data >= 0 ? _value : '-' + _value;
        return _value;
    } else {
        return null;
    }
};
export const getValueFormattedPL = (data, _expinc, acctFormat, variance): string => {
    if (data !== undefined && data !== null) {
        let _value = data >= 0 ? data : -data;
        //_value = (expinc === 1) ? data * -1 : data;
        switch (acctFormat) {
            case 'PERCENT':
                _value = Number(_value).toFixed(1);
                break;
            case 'FIXED':
                _value = Number(_value).toFixed(1);
                break;
            case 'TITLE':
                _value = '';
                break;
            case 'SPACE':
                _value = '';
                break;
            default:
                _value = Number(_value).toFixed(0);
        }
        _value = _value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        if (variance) {
            _value =
                data >= 0
                    ? displayPercentSign(acctFormat, _value)
                    : '(' + displayPercentSign(acctFormat, _value) + ')';
        } else {
            _value =
                data >= 0
                    ? displayPercentSign(acctFormat, _value)
                    : '-' + displayPercentSign(acctFormat, _value);
        }
        //_value = (data >= 0) ? _value : (variance) ? '(' + _value + ')' : '-' + _value ;
        return _value;
    } else {
        return 'n/a';
    }
};
export const displayPercentSign = (acctFormat, value): string => {
    return acctFormat == 'PERCENT' ? value + '%' : value;
};
/**
 * Sort the given array of any content,
 * compare the key of the objects instead the object itselfs if arrives
 * @param array
 * @param key
 * @param copy
 * @param desc
 */
export const sortArray = (
    array: any[],
    key?: string,
    copy?: boolean,
    desc?: boolean
): any[] => {
    if (copy) {
        const _copy = [...new Set(array)];
        return sortArray(_copy, key, false, desc);
    } else {
        array.sort((a: any, b: any) => {
            let _node1;
            let _node2;
            if (desc) {
                _node1 = key ? b[key] : b;
                _node2 = key ? a[key] : a;
            } else {
                _node1 = key ? a[key] : a;
                _node2 = key ? b[key] : b;
            }
            if (isNaN(_node1)) {
                if (_node1 < _node2) {
                    return -1;
                } else if (_node1 > _node2) {
                    return 1;
                }
                return 0;
            } else {
                return _node1 - _node2;
            }
        });
        return array;
    }
};
/**
 * Sort array of contract with a prefred order 1st Leased then Managed and then Franchised
 * @param array
 */
export const sortArrayContracts = (array: any[]): any[] => {
    if (array.length === 3) {
        return ['Leased', 'Managed', 'Franchised'];
    } else if (array.length == 2) {
        const _managed = array.indexOf('Managed') > -1;
        const _franchised = array.indexOf('Franchised') > -1;
        const _leased = array.indexOf('Leased') > -1;
        if (_leased && _managed) return ['Leased', 'Managed'];
        if (_leased && _franchised) return ['Leased', 'Franchised'];
        if (_managed && _franchised) return ['Managed', 'Franchised'];
    } else {
        return array;
    }
};
/**
 * Get rgb or rgba color
 * @param idx
 * @param opacity
 */
export const getColorFromRGBArray = (idx: number, opacity?: number): string => {
    while (idx >= rgbArray.length) {
        idx = idx - rgbArray.length;
    }
    const _rgb = rgbArray[idx];
    if (opacity) {
        return 'rgba(' + _rgb.join(',') + ',' + opacity + ')';
    } else {
        return 'rgb(' + _rgb.join(',') + ')';
    }
};
/**
 * Get rgb or rgba color for donuts with lots of items (where colors get too similar)
 * Max 10 items
 * @param idx
 * @param opacity
 */
export const getColorFromRGBArrayDonuts = (idx: number, opacity?: number): string => {
    while (idx >= rgbArrayDonuts.length) {
        idx = idx - rgbArrayDonuts.length;
    }
    const _rgb = rgbArrayDonuts[idx];
    if (opacity) {
        return 'rgba(' + _rgb.join(',') + ',' + opacity + ')';
    } else {
        return 'rgb(' + _rgb.join(',') + ')';
    }
};
/**
 * Get the string representation of a scale value
 * @param scale
 */
export const getScaleText = (scale: number): string => {
    switch (scale) {
        case 1000:
            return 'K';
        case 1000000:
            return 'M';
        default:
            return null;
    }
};
/**
 * Abort pending request
 * @param ngUnsubscribe
 * @param destroy
 */
export const abortUnsubscribe = (
    ngUnsubscribe: Subject<void>,
    destroy?: boolean
): void => {
    // This aborts all HTTP requests.
    ngUnsubscribe.next();
    if (destroy) {
        // This completes the subject properlly.
        ngUnsubscribe.complete();
        // Unsubscribe
        ngUnsubscribe.unsubscribe();
    }
};
/**
 * Method used on dataTables with drill down
 * @param nodeRows
 * @param idDataTable
 */
export const hideOrUnhideNodeRows = (
    nodeRows: NodeRows,
    idDataTable: string,
    idx = 1
): boolean => {
    nodeRows.visible = !nodeRows.visible;
    if (nodeRows.visible) {
        for (const row of nodeRows.hiddeableRows) {
            $(idDataTable + ' tbody tr:nth-child(' + row + ')').removeClass('hidden');
        }
    } else {
        hideNodeRows(nodeRows, idDataTable, idx);
    }
    return nodeRows.visible;
};
export const hideNodeRows = (nodeRows: NodeRows, idDataTable: string, idx = 1): void => {
    for (const row of nodeRows.hiddeableRows) {
        $(idDataTable + ' tbody tr:nth-child(' + row + ')').addClass('hidden');
    }
    if (nodeRows.map) {
        nodeRows.map.forEach((subnodeRows: NodeRows, _key: any) => {
            if (subnodeRows.visible) {
                subnodeRows.visible = false;
                const _expandableRow = $(
                    idDataTable + ' tbody tr:nth-child(' + subnodeRows.expandableRow + ')'
                )[0];
                _expandableRow.cells[idx].classList.remove('fa-minus-square');
                _expandableRow.cells[idx].classList.add('fa-plus-square');
                hideNodeRows(subnodeRows, idDataTable, idx);
            }
        });
    }
};
/**
 * Method used on dataTables with drill down
 * @param nodeRows
 * @param idDataTable
 */
export const hideOrUnhideNodeRowsV2 = (
    nodeRows: NodeRows,
    idDataTable: string,
    idx = 1
): boolean => {
    nodeRows.visible = !nodeRows.visible;
    if (nodeRows.visible) {
        for (const row of nodeRows.hiddeableRows) {
            $(idDataTable + ' tbody tr:nth-child(' + row + ')').removeClass('hidden');
        }
    } else {
        hideNodeRowsV2(nodeRows, idDataTable, idx);
    }
    return nodeRows.visible;
};
export const hideNodeRowsV2 = (
    nodeRows: NodeRows,
    idDataTable: string,
    idx = 1
): void => {
    for (const row of nodeRows.hiddeableRows) {
        $(idDataTable + ' tbody tr:nth-child(' + row + ')').addClass('hidden');
    }
    if (nodeRows.map) {
        nodeRows.map.forEach((subnodeRows: NodeRows, _key: any) => {
            if (subnodeRows.visible) {
                subnodeRows.visible = false;
                const _expandableRow = $(
                    idDataTable + ' tbody tr:nth-child(' + subnodeRows.expandableRow + ')'
                )[0];
                _expandableRow.cells[idx].classList.remove('fa-chevron-down');
                _expandableRow.cells[idx].classList.add('fa-chevron-right');
                hideNodeRows(subnodeRows, idDataTable, idx);
            }
        });
    }
};
/**
 * Method used on dataTables with drill down, simplified for mei-total w/ 4 layers.
 * Instead of weird map logic, merely put all expandable node rows directly in
 * mapNodeRows, then fill each node's 'hiddeableRows' and expandableRows with the rows
 * it is allowed to hide or expand.
 * @param nodeRows
 * @param idDataTable
 * @param idx
 * @param redraw: data table object should be passed when you want it to redraw after opening/closing node
 */
export const hideOrUnhideNodeRowsSimplified = (
    mapNodeRows: Map<any, NodeRows>,
    rowOfNode: number,
    idDataTable: string,
    idx = 1,
    redraw?: any
): boolean => {
    const nodeRows = mapNodeRows.get(rowOfNode);
    nodeRows.visible = !nodeRows.visible;
    if (nodeRows.visible) {
        for (const row of nodeRows.expandableRows) {
            $(idDataTable + ' tbody tr:nth-child(' + row + ')').removeClass('hidden');
        }
    } else {
        hideNodeRowsSimplified(mapNodeRows, rowOfNode, idDataTable, idx);
    }
    if (redraw) {
        redraw.rows().invalidate().draw();
    }
    return nodeRows.visible;
};
export const hideNodeRowsSimplified = (
    mapNodeRows: Map<any, NodeRows>,
    rowOfNode: number,
    idDataTable: string,
    idx = 1
): void => {
    const nodeRows = mapNodeRows.get(rowOfNode);
    for (const row of nodeRows.hiddeableRows) {
        $(idDataTable + ' tbody tr:nth-child(' + row + ')').addClass('hidden');
        if (mapNodeRows.has(row)) {
            const nodeRowToChange = mapNodeRows.get(row);
            nodeRowToChange.visible = false;
            const _expandableRow = $(idDataTable + ' tbody tr:nth-child(' + row + ')')[0];
            _expandableRow.cells[idx].classList.remove('fa-minus-square');
            _expandableRow.cells[idx].classList.add('fa-plus-square');
        }
    }
};
/**
 * Method used on dataTables with drill down, expanded from simplified to
 * accommodate huge tables (e.g. cp-day)
 * Instead of weird map logic, merely put all expandable node rows directly in
 * mapNodeRows, then fill each node's 'hiddeableRows' and expandableRows with the rows
 * it is allowed to hide or expand.
 * @param mapNodeRows
 * @param rowOfNode
 * @param idDataTable
 * @param noLongerHidden: array of nodes that are currently showing (that way you only hide actually visible rows)
 */
export const hideOrUnhideNodeRowsBigData = (
    mapNodeRows: Map<unknown, NodeRows>,
    rowOfNode: number,
    idDataTable: string,
    noLongerHidden: number[]
): [boolean, number[]] => {
    const nodeRows = mapNodeRows.get(rowOfNode);
    nodeRows.visible = !nodeRows.visible;
    let newNoLongerHidden: number[];
    if (nodeRows.visible) {
        for (const row of nodeRows.expandableRows) {
            $(idDataTable + ' tbody tr:nth-child(' + row + ')').show();
            noLongerHidden.push(row);
        }
    } else {
        for (const row of nodeRows.hiddeableRows) {
            if (noLongerHidden.includes(row)) {
                $(idDataTable + ' tbody tr:nth-child(' + row + ')').hide();
            }
            if (mapNodeRows.has(row)) {
                const nodeRowToChange = mapNodeRows.get(row);
                nodeRowToChange.visible = false;
                const _expandableRow = $(
                    idDataTable + ' tbody tr:nth-child(' + row + ')'
                )[0];
                _expandableRow.cells[0].classList.remove('fa-minus-square');
                _expandableRow.cells[0].classList.add('fa-plus-square');
            }
        }
        newNoLongerHidden = noLongerHidden.filter(
            (el) => !nodeRows.hiddeableRows.includes(el)
        );
    }
    return [nodeRows.visible, newNoLongerHidden];
};
/**
 * Method used on dataTables with drill down
 * @param totallyExpanded
 * @param mapNodeRows
 * @param dataTable
 * @param idDataTable
 * @param idx column with the expandable cell
 */
export const expandAll = (
    totallyExpanded: boolean,
    mapNodeRows: Map<any, NodeRows>,
    dataTable: any,
    idDataTable: string,
    idx = 1
): boolean => {
    totallyExpanded = !totallyExpanded;
    const _button = dataTable.buttons()[0];
    _button.node.innerText = totallyExpanded ? 'Collapse all' : 'Expand all';
    mapNodeRows.forEach((nodeRows: NodeRows, _key: any) => {
        if (nodeRows.visible !== totallyExpanded) {
            const _expandableRow = $(
                idDataTable + ' tbody tr:nth-child(' + nodeRows.expandableRow + ')'
            )[0];
            nodeRows.visible = !nodeRows.visible;
            if (nodeRows.visible) {
                _expandableRow.cells[idx].classList.remove('fa-plus-square');
                _expandableRow.cells[idx].classList.add('fa-minus-square');
                for (const row of nodeRows.hiddeableRows) {
                    $(idDataTable + ' tbody tr:nth-child(' + row + ')').removeClass(
                        'hidden'
                    );
                }
            } else {
                _expandableRow.cells[idx].classList.remove('fa-minus-square');
                _expandableRow.cells[idx].classList.add('fa-plus-square');
                for (const row of nodeRows.hiddeableRows) {
                    $(idDataTable + ' tbody tr:nth-child(' + row + ')').addClass(
                        'hidden'
                    );
                }
            }
        }
    });
    return totallyExpanded;
};
/**
 * Method used on dataTables with drill down
 * @param totallyExpanded
 * @param mapNodeRows
 * @param dataTable
 * @param idDataTable
 * @param idx column with the expandable cell
 */
export const expandAllV2 = (
    totallyExpanded: boolean,
    mapNodeRows: Map<any, NodeRows>,
    dataTable: any,
    idDataTable: string,
    idx = 1
): boolean => {
    totallyExpanded = !totallyExpanded;
    const _button = dataTable.buttons()[0];
    _button.node.innerText = totallyExpanded ? 'Collapse all' : 'Expand all';
    mapNodeRows.forEach((nodeRows: NodeRows, _key: any) => {
        if (nodeRows.visible !== totallyExpanded) {
            const _expandableRow = $(
                idDataTable + ' tbody tr:nth-child(' + nodeRows.expandableRow + ')'
            )[0];
            nodeRows.visible = !nodeRows.visible;
            if (nodeRows.visible) {
                _expandableRow.cells[idx].classList.remove('fa-chevron-right');
                _expandableRow.cells[idx].classList.add('fa-chevron-down');
                for (const row of nodeRows.hiddeableRows) {
                    $(idDataTable + ' tbody tr:nth-child(' + row + ')').removeClass(
                        'hidden'
                    );
                }
            } else {
                _expandableRow.cells[idx].classList.remove('fa-chevron-down');
                _expandableRow.cells[idx].classList.add('fa-chevron-right');
                for (const row of nodeRows.hiddeableRows) {
                    $(idDataTable + ' tbody tr:nth-child(' + row + ')').addClass(
                        'hidden'
                    );
                }
            }
            _expandableRow.cells[idx].classList.remove('fa-plus-square');
            _expandableRow.cells[idx].classList.remove('fa-minus-square');
        }
    });
    return totallyExpanded;
};
/**
 * Method used on gauges to get the right color
 * @param colorArray an associative array with [keyRangeMax=>colorValue]
 * @return colorValue the value needed
 */
export const getColorForRanges = (colorArray: any, percent: number): any => {
    const keys = Object.keys(colorArray);
    for (const key in keys) {
        if (percent < parseInt(keys[key])) {
            return colorArray[keys[key]];
        }
    }
    return colorArray[keys[keys.length - 1]];
};
export const versus = [
    { label: 'vs STLY', data: 'STLY', id: '' }
    //,{ label: 'vs ST19', data: 'ST19', id: '_19' }
];
export const getDateTime = (): string => {
    const now = new Date();
    // Create formatted time
    const yyyy = now.getFullYear();
    const mm = now.getMonth() < 9 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1; // getMonth() is zero-based
    const dd = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
    const hh = now.getHours() < 10 ? '0' + now.getHours() : now.getHours();
    const mmm = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes();
    const ss = now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds();
    return yyyy.toString() + mm + dd + '-' + hh + mmm + ss;
};
export const checkAllowedHtl = (
    selected_htl_id: number[],
    allowed_htl_id: number[],
    dash_id: string
): boolean => {
    const _allowedHtl_id = allowed_htl_id.filter(
        (node: number) => selected_htl_id.indexOf(node) !== -1
    );
    return _allowedHtl_id.length === 0 ? (dash_id === 'summary' ? true : false) : true;
};
export const createDatasource = (
    input: string[] | LabelDataOption[]
): LabelDataOption[] => {
    const output: LabelDataOption[] = [];
    input.forEach((element) => {
        output.push({ label: element, data: element });
    });
    return output;
};

export type LabelDataOption = {
    label: string;
    data: string;
};

export const getMonthFromString = (mon: string): number => {
    const d = Date.parse(mon + '1, 2012');
    if (!isNaN(d)) {
        return new Date(d).getMonth() + 1;
    }
    return -1;
};

export const monthStringToNumber = (str: string): string => {
    return str
        .replace('Jan', '1')
        .replace('Feb', '2')
        .replace('Mar', '3')
        .replace('Apr', '4')
        .replace('May', '5')
        .replace('Jun', '6')
        .replace('Jul', '7')
        .replace('Aug', '8')
        .replace('Sep', '9')
        .replace('Oct', '10')
        .replace('Nov', '11')
        .replace('Dec', '12');
};

export const findTreeNodeByLabel = (nodes: any[], label: string) => {
    for (const node of nodes) {
        if (node.item === label) {
            return node;
        }
        if (node.children && node.children.length) {
            const foundNode = findTreeNodeByLabel(node.children, label);
            if (foundNode) {
                return foundNode;
            }
        }
    }
    null;
};

export const eachFirstLetterToUpperCase = (str: string): string => {
    return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

// create a function that returns the prev_ops_area attribute from opsAreaMapping based on the given ops_area
export const getPrevOpsAreaFromOpsArea = (ops_area: string): string => {
    return opsAreaMapping[ops_area].prev_ops_area;
};

export const getPrevOpsAreaFromCountry = (country: string): string => {
    return opsAreaMapping[country].prev_ops_area;
};

export const getOpsAreaFromPrevOpsArea = (prev_ops_area: string): string => {
    return opsAreaMapping[prev_ops_area].ops_area;
};

export const getOpsAreaFromCountry = (country: string): string => {
    return opsAreaMapping[country].ops_area;
};

export const getMonthName = (monthIndex): string => {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];
    return months[monthIndex];
};

export const sanitizeStringOnlyAlphaNum = (str: string): string => {
    if (!str) {
        return '';
    }
    return str.replace(/[^a-zA-Z0-9_.-]/g, '');
};
