import { AfterViewInit, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../core/services/global.service';
import { Router } from '@angular/router';

@Component({
    selector: 'alert',
    templateUrl: './alert.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements AfterViewInit, OnDestroy {
    show = false;
    title: string;
    textIsArray: boolean;
    text: string | string[];
    lock = false;
    private subscription: Subscription;
    constructor(private globalService: GlobalService, private router: Router) {
        this.subscription = globalService.alertObservable.subscribe((data) => {
            if (!data) {
                this.show = false;
                return;
            }
            this.show = true;
            this.title = data.title;
            this.textIsArray = Array.isArray(data.text);
            this.text = data.text;
        });
    }
    public ngAfterViewInit(): void {
        $('.alert-box-inner').click(() => {
            this.lock = true;
        });
        $('#alert').click(() => {
            if (!this.lock) {
                this.globalService.alertSubj.next(null);
                if (
                    this.title === 'Hotels Included Option' ||
                    this.title === 'Warning : review the options'
                ) {
                    setTimeout(() => {
                        $('#optionsButtonToggle')?.click();
                    }, 0);
                }
                if (this.title === 'Warning : review hotel selection') {
                    setTimeout(() => {
                        $('#m_quick_sidebar_toggle').click();
                    }, 0);
                }
                if (this.title === 'Session expired.') {
                    setTimeout(() => {
                        this.router.navigate(['/logout']);
                    }, 0);
                }
            }
            this.lock = false;
        });
    }
    public close(): void {
        if (
            this.title === 'Hotels Included Option' ||
            this.title === 'Warning : review the options'
        ) {
            setTimeout(() => {
                $('#optionsButtonToggle')?.click();
            }, 0);
        }
        if (this.title === 'Warning : review hotel selection') {
            setTimeout(() => {
                $('#m_quick_sidebar_toggle').click();
            }, 0);
        }
        if (this.title === 'Session expired') {
            setTimeout(() => {
                this.router.navigate(['/logout']);
            }, 0);
        }
        this.globalService.alertSubj.next(null);
    }
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
