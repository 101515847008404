import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';
/**
 * CanActivate implementation to check the user
 */
@Injectable({ providedIn: 'root' })
export class AuthGuard {
    constructor(
        private userService: UserService,
        private router: Router
    ) {}
    /**
     * This canActivate its called on AppRoutingModule (when the path its not /login or /logout) and on rhgRoutes (/dashboard)
     * If the token isn't on localStorage we do the call to access/recover and set de Data on UserService.
     * If the token exists but the user was not defined, we call to access/recover anyway
     */
    async canActivate(
        _route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        console.log('AuthWithRedirectGuard.canActivate', state.url);
        console.log(
            'canActivate return_url in localStorage: ' +
                localStorage.getItem('return_url')
        );
        const authenticated = await this.userService.checkAuthenticationAsPromise(
            state.url
        );
        console.log('canActivate await value: ' + authenticated);

        if (!authenticated) {
            console.log('canActivate redirect to login');
            this.router.navigate(['/login']);
        }
        return authenticated;
    }
}
