import { Injectable } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { filter, share } from 'rxjs/operators';

/**
 * Use this service for broadcasts and subscribes.
 * If one Component uses it, be sure to call unsubscribe on ngDestroy.
 */
@Injectable({ providedIn: 'root' })
export class EventManagerService {
    observable: Observable<any>;
    observer: Observer<any>;

    constructor() {
        this.observable = new Observable((observer: Observer<any>) => {
            this.observer = observer;
        }).pipe(share());
    }

    /**
     * Method to broadcast the event to observer
     */
    broadcast(event): void {
        if (this.observer != null) {
            this.observer.next(event);
        }
    }

    /**
     * Method to subscribe to an event with callback
     */
    subscribe(eventName, callback): Subscription {
        const subscriber: Subscription = this.observable
            .pipe(
                filter((event) => {
                    return event.name === eventName;
                })
            )
            .subscribe(callback);
        return subscriber;
    }
}
